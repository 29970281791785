import '../../../styles/styles.css';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import RightSectionPlaceholder from '../../../layouts/authentication/RightSectionPlaceholder';
import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import InputWithoutLabel from '../../../components/input/InputWithoutLabel';
import InputPassword from '../../../components/input/InputPassword';
import AuthFormHeader from '../../../components/form/AuthFormHeader';
import TopHeader from '../../../components/global/TopHeader';
import Error from '../../../components/global/ui/alerts/Error';
import { routes } from '../../../constants/routes';
import { useEffect, useState } from 'react';
import LoginTwoFactorAuthentication from '../../../feature/Authentication/LoginTwoFactorAuthentication';
import { IUserAuthData } from '../../../interfaces/user';
import { useLogin } from '../Login/useLogin';
import { useIntercom } from 'react-use-intercom';

export default function NoRecaptchaLogin() {
  const { login, loading, errorMessage } = useLogin();
  const [otpModalShow, setOtpModalShow] = useState<boolean>(false);
  const [userAuthData, setUserAuthData] = useState<IUserAuthData | null>(null);
  const { shutdown } = useIntercom();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter a valid email address').required('Email address is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: async (values) => handleLogin(values)
  });

  useEffect(() => {
    shutdown();
  }, []);

  const handleLogin = async (values: FormikValues) => {
    const response = await login(values.email, values.password, true);
    if (!response.token && response.two_factor_setup) {
      setUserAuthData(response);
      setOtpModalShow(true);
    }
  };

  return (
    <div>
      {otpModalShow && userAuthData && (
        <LoginTwoFactorAuthentication
          user={userAuthData}
          closeModal={() => setOtpModalShow(false)}
        />
      )}
      <main className="main-container">
        <TopHeader />

        <div className="grid lg:grid-cols-2 sides-container">
          <section className="pt-[10vh] px-5 flex flex-col items-center">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}
              className="w-full md:w-1/2 flex flex-col">
              <AuthFormHeader title={'Log in'} description={'Log into your account.'} />
              <InputWithoutLabel
                name={'email'}
                type={'email'}
                value={formik.values.email}
                changeHandler={formik.handleChange}
                placeholder={'Your email'}
                autoFocus={true}
              />
              <p className={'text-sm text-red-500'}>
                {formik.touched.email && formik.errors.email}
              </p>

              <InputPassword
                name={'password'}
                value={formik.values.password}
                changeHandler={formik.handleChange}
                placeholder={'Enter Password'}
              />
              <p className={'text-sm text-red-500'}>
                {formik.touched.password && formik.errors.password}
              </p>
              <p className={'mt-2 mb-3 text-sm'}>
                Forgot Password?{' '}
                <Link to={routes.RESET_PASSWORD} className="text-secondary mb-3 underline">
                  Click here
                </Link>
              </p>

              <div className={'mt-5'}>
                <PrimaryButton
                  title={loading ? 'Logging into account...' : 'Login'}
                  disabled={loading}
                  handler={() => formik.handleSubmit()}
                />
                {errorMessage && <Error value={errorMessage} />}

                <p className="text-sm text-center black mt-5">
                  Don&apos;t have an account?{' '}
                  <Link to={routes.REGISTER} className="text-secondary">
                    Sign Up
                  </Link>
                </p>
              </div>
            </form>
          </section>
          <RightSectionPlaceholder />
        </div>
      </main>
    </div>
  );
}
