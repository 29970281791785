import { useEffect, useState } from 'react';
import { IWebsiteSubscription } from '../../interfaces/subscription';

interface SearchFormProps {
  websites: IWebsiteSubscription[];
  setSearchResults: any;
}

export default function SearchWebsites({ websites, setSearchResults }: SearchFormProps) {
  const handleSearch = (e: any) => e.preventDefault();

  const handleSearchChange = (e: any) => {
    if (!e.target.value) return setSearchResults(websites);
    const results = websites.filter(
      (item) =>
        item.website?.name?.toLowerCase().includes(e.target?.value) ||
        item.website?.main_url?.toLowerCase().includes(e.target?.value)
    );
    setSearchResults(results);
  };

  const [filterShow, setFilterShow] = useState<boolean>(false);

  const [filters, setFilters] = useState({
    status: 'All',
    type: 'All',
    verificationStatus: 'All',
    platform: 'All',
    plan: 'All',
    account: 'All'
  });

  useEffect(() => {
    const newFilteredData =
      websites &&
      websites.filter((item) => {
        const statusCondition = filters.status === 'All' || item.status === filters.status;

        const platformCondition =
          filters.platform === 'All' || item.website?.platform === filters.platform;

        const accountCondition =
          filters.account === 'All' || item.created_by.full_name === filters.account;

        const planCondition =
          filters.plan === 'All'
            ? true
            : filters.plan === 'Starter'
            ? item.plan === 1
            : filters.plan === 'Grow'
            ? item.plan === 2
            : filters.plan === 'Scale'
            ? item.plan === 3
            : filters.plan === 'Scale Plus'
            ? item.plan === 4
            : filters.plan === 'Basic'
            ? item.plan === 0
            : false;

        const verificationStatusCondition =
          filters.verificationStatus === 'All'
            ? true
            : filters.verificationStatus === 'Verified'
            ? item.website?.verified === 1
            : filters.verificationStatus === 'Pending'
            ? item.website?.verified === 0
            : false;
        return (
          statusCondition &&
          platformCondition &&
          verificationStatusCondition &&
          planCondition &&
          accountCondition
        );
      });
    setSearchResults(newFilteredData);
  }, [filters, websites]);

  const statusOptions = ['All', ...new Set(websites && websites.map((item) => item.status))];
  const platformOptions = [
    'All',
    ...new Set(websites && websites.map((item) => item.website?.platform))
  ];
  const accountOptions = [
    'All',
    ...new Set(websites && websites.map((item) => item.created_by.full_name))
  ];
  const verificationOptions = [
    'All',
    ...new Set(
      websites && websites.map((item) => (item.website?.verified == 0 ? 'Pending' : 'Verified'))
    )
  ];
  const planOptions = [
    'All',
    ...new Set(
      websites &&
        websites.map((item) =>
          item.plan == 1
            ? 'Starter'
            : item.plan == 2
            ? 'Grow'
            : item.plan == 3
            ? 'Scale'
            : item.plan == 4
            ? 'Scale Plus'
            : 'Basic'
        )
    )
  ];

  const handleFilterChange = (filterName: keyof typeof filters, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  return (
    <div className={'flex flex-col gap-2'}>
      <div className={'flex gap-2'}>
        <form
          onSubmit={handleSearch}
          className={
            'border border-gray-300 bg-gray-100 rounded px-4 py-2 flex gap-3 items-start w-full lg:w-[20vw]'
          }>
          <span>
            <i className="fi fi-rr-search"></i>
          </span>
          <input
            type={'search'}
            id={'search'}
            placeholder={'Search websites'}
            className={'bg-transparent w-full text-sm'}
            onChange={handleSearchChange}
          />
        </form>
        <button
          type={'button'}
          onClick={() => setFilterShow(!filterShow)}
          className={`rounded px-3 py-2 flex items-center gap-2 border border-[#008160] ${
            filterShow ? 'bg-emerald-100' : ''
          } hover:bg-emerald-100 text-[#008160]`}>
          <span>
            <i className="fi fi-rr-filter text-xs"></i>
          </span>
          <span className={'text-xs'}>Filter websites</span>
        </button>
      </div>
      {filterShow && (
        <div>
          <h4 className={'text-sm'}>Filter results</h4>
          <div className={'flex gap-2 mt-1'}>
            <div>
              <label className={'text-sm'}>Platform</label>
              <select
                className={`px-2 py-2 w-full rounded border text-sm border-gray-300 capitalize`}
                name={'platform'}
                value={filters.platform}
                onChange={(event) => handleFilterChange('platform', event.target.value)}>
                {platformOptions &&
                  platformOptions.map((item: any, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label className={'text-sm'}>Status</label>
              <select
                className={`px-2 py-2 w-full rounded border text-sm border-gray-300 capitalize`}
                name={'status'}
                value={filters.status}
                onChange={(event) => handleFilterChange('status', event.target.value)}>
                {statusOptions &&
                  statusOptions.map((item: any, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label className={'text-sm'}>Verification</label>
              <select
                className={`px-2 py-2 w-full rounded border text-sm border-gray-300`}
                name={'verificationStatus'}
                value={filters.verificationStatus}
                onChange={(event) => handleFilterChange('verificationStatus', event.target.value)}>
                {verificationOptions &&
                  verificationOptions.map((item: any, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label className={'text-sm'}>Plan</label>
              <select
                className={`px-2 py-2 w-full rounded border text-sm border-gray-300`}
                name={'plan'}
                value={filters.plan}
                onChange={(event) => handleFilterChange('plan', event.target.value)}>
                {planOptions &&
                  planOptions.map((item: any, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label className={'text-sm'}>Account</label>
              <select
                className={`px-2 py-2 w-full rounded border text-sm border-gray-300`}
                name={'plan'}
                value={filters.account}
                onChange={(event) => handleFilterChange('account', event.target.value)}>
                {accountOptions &&
                  accountOptions.map((item: any, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
