import { Link } from 'react-router-dom';
import { useAuthUser } from 'react-auth-kit';
import { useLogoutUser } from '../../util/global';
import { routes } from '../../constants/routes';
import { useContext, useState } from 'react';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import heatmapLogo from '../../assets/heatmap-logo.svg';
import betaLogo from '../../assets/heatmap-logo_beta-02.png';
import GiveFeedback from '../../feature/Clients/GiveFeedback';
import OpenAIPrompt from '../../feature/Dashboard/OpenAIPrompt';

export default function SideBar({ closeSidebar }: { closeSidebar?: any }) {
  const auth = useAuthUser();
  const { logoutUser } = useLogoutUser();

  const [newFeedbackOpen, setNewFeedbackOpen] = useState<boolean>(false);

  const { profile } = useContext(OrganizationContext);

  // Sidebar menu options for a super account
  const superUserOptions = [
    {
      name: 'Dashboard',
      icon: <i className="fi fi-rr-layout-fluid text-xl lg:text-xl"></i>,
      link: routes.DASHBOARD
    },
    {
      name: 'All websites',
      icon: <i className="fi fi-rr-browser text-xl lg:text-xl"></i>,
      link: routes.WEBSITES
    },
    {
      name: 'Subscriptions',
      icon: <i className="fi fi-rr-box-open text-xl lg:text-xl"></i>,
      link: routes.SUBSCRIPTIONS
    },
    {
      name: 'Coupons',
      icon: <i className="fi fi-rr-badge-percent text-xl lg:text-xl"></i>,
      link: routes.COUPONS
    },
    {
      name: 'Clients & Users',
      icon: <i className="fi fi-rr-users text-xl lg:text-xl"></i>,
      link: routes.CLIENTS
    },
    {
      name: 'Account Settings',
      icon: <i className="fi fi-rr-settings text-xl lg:text-xl"></i>,
      link: routes.ACCOUNT_PROFILE
    },
    {
      name: 'Admin Accounts',
      link: routes.ACCOUNT_TEAM,
      id: 3,
      icon: <i className="fi fi-rr-users text-xl lg:text-xl"></i>
    },
    {
      name: 'Notifications',
      icon: <i className="fi fi-rr-bell text-xl lg:text-xl"></i>,
      link: routes.NOTIFICATIONS
    },
    {
      name: 'Help & Support',
      icon: <i className="fi fi-rr-interrogation text-xl lg:text-xl"></i>,
      link: routes.SUPPORT
    },
    {
      name: 'Feedback',
      icon: <i className="fi fi-rr-star-comment-alt text-xl lg:text-xl"></i>,
      link: routes.FEEDBACK
    },
    {
      name: 'System Logs',
      icon: <i className="fi fi-rr-interactive text-xl lg:text-xl"></i>,
      link: routes.LOGS
    },
    {
      name: 'Platform Settings',
      icon: <i className="fi fi-rr-settings-sliders text-xl lg:text-xl"></i>,
      link: routes.PLATFORM_SETTINGS
    }
    // {
    //   name: 'OpenAI Documents',
    //   icon: <i className="fi fi-rr-file-ai text-xl lg:text-xl"></i>,
    //   link: routes.OPEN_AI_DOCUMENTS
    // }
    // {
    //   name: 'OpenAI Documents',
    //   icon: <i className="fi fi-rr-file-ai text-xl lg:text-xl"></i>,
    //   link: routes.OPEN_AI_DOCUMENTS
    // }
  ];

  // Sidebar menu options for an account holder or owner
  const accountHolderOptions = [
    {
      name: 'Dashboard',
      icon: <i className="fi fi-rr-layout-fluid text-xl lg:text-xl"></i>,
      link: routes.DASHBOARD
    },
    {
      name: 'Add website',
      icon: <i className="fi fi-rr-globe text-xl lg:text-xl"></i>,
      link: routes.PRICING
    },
    {
      name: 'All websites',
      icon: <i className="fi fi-rr-browser text-xl lg:text-xl"></i>,
      link: routes.WEBSITES
    },
    {
      name: 'Payments',
      icon: <i className="fi fi-rr-credit-card text-xl lg:text-xl"></i>,
      link: routes.ACCOUNT_PAYMENTS
    },
    {
      name: 'Account Settings',
      icon: <i className="fi fi-rr-settings text-xl lg:text-xl"></i>,
      link: routes.ACCOUNT_PROFILE
    },
    {
      name: 'Team management',
      link: routes.ACCOUNT_TEAM,
      id: 3,
      icon: <i className="fi fi-rr-users text-xl lg:text-xl"></i>
    },
    {
      name: 'Notifications',
      icon: <i className="fi fi-rr-bell text-xl lg:text-xl"></i>,
      link: routes.NOTIFICATIONS
    },
    {
      name: 'Help & Support',
      icon: <i className="fi fi-rr-interrogation text-xl lg:text-xl"></i>,
      link: routes.SUPPORT
    }
  ];

  // Sidebar menu options for a guest account user with read access
  const guestUserReadOptions = [
    {
      name: 'Dashboard',
      icon: <i className="fi fi-rr-layout-fluid text-xl lg:text-xl"></i>,
      link: routes.DASHBOARD
    },
    {
      name: 'All websites',
      icon: <i className="fi fi-rr-browser text-xl lg:text-xl"></i>,
      link: routes.WEBSITES
    },
    {
      name: 'Payments',
      icon: <i className="fi fi-rr-credit-card text-xl lg:text-xl"></i>,
      link: routes.ACCOUNT_PAYMENTS
    },
    {
      name: 'Account Settings',
      icon: <i className="fi fi-rr-settings text-xl lg:text-xl"></i>,
      link: routes.ACCOUNT_PROFILE
    },
    {
      name: 'Notifications',
      icon: <i className="fi fi-rr-bell text-xl lg:text-xl"></i>,
      link: routes.NOTIFICATIONS
    },
    {
      name: 'Help & Support',
      icon: <i className="fi fi-rr-interrogation text-xl lg:text-xl"></i>,
      link: routes.SUPPORT
    }
  ];

  // Sidebar menu options for a guest user with read and write access
  const guestUserWriteOptions = [
    {
      name: 'Dashboard',
      icon: <i className="fi fi-rr-layout-fluid text-xl lg:text-xl"></i>,
      link: routes.DASHBOARD
    },
    {
      name: 'All websites',
      icon: <i className="fi fi-rr-browser text-xl lg:text-xl"></i>,
      link: routes.WEBSITES
    },
    {
      name: 'Payments',
      icon: <i className="fi fi-rr-credit-card text-xl lg:text-xl"></i>,
      link: routes.ACCOUNT_PAYMENTS
    },
    {
      name: 'Account Settings',
      icon: <i className="fi fi-rr-settings text-xl lg:text-xl"></i>,
      link: routes.ACCOUNT_PROFILE
    },
    {
      name: 'Team management',
      link: routes.ACCOUNT_TEAM,
      icon: <i className="fi fi-rr-users text-xl lg:text-xl"></i>
    },
    {
      name: 'Notifications',
      icon: <i className="fi fi-rr-bell text-xl lg:text-xl"></i>,
      link: routes.NOTIFICATIONS
    },
    {
      name: 'Help & Support',
      icon: <i className="fi fi-rr-interrogation text-xl lg:text-xl"></i>,
      link: routes.SUPPORT
    }
  ];

  const isGuestWithReadAccess = profile.profile_details?.permission == 'read';
  const isGuestWithWriteAccess = profile.profile_details?.permission == 'write';
  const isAccountHolder = profile.profile_details?.permission == 'admin';
  const isSuperUser = auth()?.super_user === 1;

  return (
    <div className={'w-full bg-white relative h-screen'}>
      {newFeedbackOpen && <GiveFeedback closeModal={() => setNewFeedbackOpen(false)} />}
      {/*{aiPromptOpen && <OpenAIPrompt closeModal={() => setAiPromptOpen(false)} />}*/}

      <div className="p-5 flex justify-between items-center">
        <Link to={routes.DASHBOARD} id="logo-link" className={'flex items-center gap-1'}>
          <img src={heatmapLogo} alt="" className={'w-3/4 lg:w-4/6'} />
          <img src={betaLogo} alt="" className={'w-1/4 lg:w-2/6'} />
        </Link>
        <button className={'lg:hidden'} type={'button'} onClick={closeSidebar}>
          <i className="fi fi-rr-cross text-lg"></i>
        </button>
      </div>
      <div className={'lg:hidden border-b-2 border-gray-200'}>
        <div className={'bg-[#008160] text-white pt-5'}>
          <div className={'flex justify-between items-start px-5'}>
            <span
              className={
                'w-14 h-14 rounded-full bg-gray-200 text-gray-600 font-medium text-xl flex justify-center items-center uppercase'
              }>
              {auth()?.full_name?.charAt(0)}
            </span>
            <span className={'rounded-full bg-emerald-100 text-[#008160] px-2 py-1 text-xs'}>
              {auth()?.account_holder == 1 && auth()?.super_user == 1
                ? 'Administrator'
                : auth()?.account_holder == 1 && auth()?.super_user == 0
                ? 'Account Owner'
                : 'Guest'}
            </span>
          </div>
          <div className={'px-5 pt-3 pb-5'}>
            <div>
              <h5 className={'text-base font-medium capitalize'}>{auth()?.full_name}</h5>
              <p className={'text-sm'}>{auth()?.email}</p>
            </div>
            <div>
              <Link to={routes.ACCOUNT_PROFILE} className={'text-sm underline'}>
                See profile
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={'flex flex-grow flex-col justify-between'}>
        <div
          className={
            'flex flex-col bg-white h-[62vh] md:h-[70vh] lg:h-[83vh] slim-scroll overflow-y-auto'
          }>
          {isSuperUser &&
            superUserOptions.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                className={`${
                  location.pathname === item.link
                    ? 'bg-emerald-50 text-[#008160] hover:bg-emerald-50 border-l-2 md:border-l-4 border-l-[#008160]'
                    : 'hover:text-[#008160] text-gray-700 border-b border-l-2 md:border-l-4 border-l-transparent border-gray-100'
                } px-3 md:px-5 pt-3 md:pt-3 pb-2 md:pb-2 md:text-sm flex gap-3`}>
                <span>{item.icon}</span>
                <span>{item.name}</span>
              </Link>
            ))}
          {isAccountHolder &&
            !isSuperUser &&
            accountHolderOptions.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                className={`${
                  location.pathname === item.link
                    ? 'bg-emerald-50 text-[#008160] hover:bg-emerald-50 border-l-2 md:border-l-4 border-l-[#008160]'
                    : 'hover:text-[#008160] text-gray-700 border-b border-l-2 md:border-l-4 border-l-transparent border-gray-100'
                } px-3 md:px-5 pt-3 md:pt-3 pb-2 md:pb-2 md:text-sm flex gap-3`}>
                <span>{item.icon}</span>
                <span>{item.name}</span>
              </Link>
            ))}
          {isGuestWithWriteAccess &&
            accountHolderOptions.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                className={`${
                  location.pathname === item.link
                    ? 'bg-emerald-50 text-[#008160] hover:bg-emerald-50 border-l-2 md:border-l-4 border-l-[#008160]'
                    : 'hover:text-[#008160] text-gray-700 border-b border-l-2 md:border-l-4 border-l-transparent border-gray-100'
                } px-3 md:px-5 pt-3 md:pt-3 pb-2 md:pb-2 md:text-sm flex gap-3`}>
                <span>{item.icon}</span>
                <span>{item.name}</span>
              </Link>
            ))}
          {isGuestWithReadAccess &&
            accountHolderOptions.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                className={`${
                  location.pathname === item.link
                    ? 'bg-emerald-50 text-[#008160] hover:bg-emerald-50 border-l-2 md:border-l-4 border-l-[#008160]'
                    : 'hover:text-[#008160] text-gray-700 border-b border-l-2 md:border-l-4 border-l-transparent border-gray-100'
                } px-3 md:px-5 pt-3 md:pt-3 pb-2 md:pb-2 md:text-sm flex gap-3`}>
                <span>{item.icon}</span>
                <span>{item.name}</span>
              </Link>
            ))}

          {profile.profile_details?.super_user == 0 && (
            <button
              type={'button'}
              onClick={() => setNewFeedbackOpen(true)}
              className={
                'hover:text-[#008160] text-gray-700 border-b border-l-2 md:border-l-4 border-l-transparent border-gray-100 px-3 md:px-5 pt-3 md:pt-4 pb-2 md:pb-3 md:text-sm flex gap-3'
              }>
              <span>
                <i className="fi fi-rr-star-comment-alt text-xl lg:text-xl"></i>
              </span>
              <span>Give Feedback</span>
            </button>
          )}

          {/*{profile.profile_details?.super_user == 1 && (*/}
          {/*  <button*/}
          {/*    type={'button'}*/}
          {/*    onClick={() => setAiPromptOpen(true)}*/}
          {/*    className={*/}
          {/*      'hover:text-[#008160] text-gray-700 border-b border-l-2 md:border-l-4 border-l-transparent border-gray-100 px-3 md:px-5 pt-3 md:pt-4 pb-2 md:pb-3 md:text-sm flex gap-3'*/}
          {/*    }>*/}
          {/*    <span>*/}
          {/*      <i className="fi fi-rr-file-ai text-xl lg:text-xl"></i>*/}
          {/*    </span>*/}
          {/*    <span>Update OpenAI Documents</span>*/}
          {/*  </button>*/}
          {/*)}*/}
        </div>
      </div>

      <div
        className={
          'hidden lg:block text-sm w-full absolute bottom-0 left-0 bg-emerald-200 text-gray-600 px-3 md:px-5 py-2 md:py-3 text-center'
        }>
        {auth()?.account_holder == 1 && isSuperUser
          ? 'Administrator'
          : auth()?.account_holder == 1 && auth()?.super_user == 0
          ? 'Account Owner'
          : 'Guest'}
      </div>
      <button
        type={'button'}
        onClick={() => logoutUser()}
        className={'lg:hidden absolute bottom-0 left-0 w-full py-2 text-sm text-white bg-red-500'}>
        Logout
      </button>
    </div>
  );
}
