import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { IPlan, IPlanInterval } from '../../../interfaces/components';
import { usePayment } from '../../../pages/main/Pricing/usePayment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';
import Error from '../../../components/global/ui/alerts/Error';
import { useAuthHeader, useAuthUser, useSignIn } from 'react-auth-kit';
import {
  AMPLITUDE_EVENTS,
  basicPlanItems,
  businessPlanItems,
  ORGANIZATION_ID,
  premiumPlanItems,
  SUBSCRIPTION_ID_KEY
} from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';
import PackageItem from '../../Plans/PlanPackageItem';
import { plan } from '../../../pages/main/Plans';
import PlanButton from '../../../components/global/ui/buttons/PlanButton';
import { trackAmplitudeEvent } from '../../../util/amplitudeService';

export default function NewFirstTimeCheckoutForm({
  plan,
  interval
}: {
  plan: IPlan;
  interval: IPlanInterval;
}) {
  const { addPaymentCard, confirmPayment, makePayment, abandonCart, errorMessage, loading } =
    usePayment();
  const [message, setMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const signIn = useSignIn();

  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  const { fetchOrganizationData, profile } = useContext(OrganizationContext);

  const [tokenType, token] = authHeader().split(' ');

  const [error, setError] = useState<string | null | undefined>(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter a valid email').required('Enter your email'),
    coupon: Yup.string(),
    number: Yup.string()
      .max(16)
      .min(14, 'Card number must be at least 14 digits')
      .trim()
      .required('Enter your card number'),
    expMonth: Yup.string()
      .typeError('Expiry month must be digits 01 - 12')
      .max(2, 'Expiry month must be at most 2 digits')
      .min(1, 'Expiry month must be at least 1 digits')
      .required('Expiry month is required'),
    expYear: Yup.string()
      .typeError('Expiry year must be digits')
      .max(2, 'Expiry year must be at most 2 digits')
      .min(2, 'Expiry year must be at least 2 digits')
      .required('Expiry year is required'),
    name: Yup.string().required('Name on card is required'),
    cvc: Yup.string().max(4).required('CVC is required')
  });

  const formik = useFormik({
    initialValues: {
      plan: plan && plan.value,
      name: '',
      coupon: '',
      interval: interval && interval.value,
      email: auth() && auth()?.email,
      number: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      addressOne: '',
      addressTwo: '',
      city: '',
      country: ''
    },
    validationSchema,
    onSubmit: async (values) => handleNewSubscription(values)
  });

  // useEffect(() => {
  //   const handleBeforeUnload = async (event: any) => {
  //     // Perform actions before the component unloads
  //     event.preventDefault();
  //     // event.returnValue = '';
  //     await abandonCart({ plan: plan.value });
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  const handleNewSubscription = async (values: FormikValues) => {
    setMessage(null);
    setError(null);
    const addCardResponse = await addPaymentCard({
      name: values.name,
      number: values.number,
      expMonth: values.expMonth,
      expYear: values.expYear,
      cvc: values.cvc,
      addressOne: values.addressOne,
      addressTwo: values.addressTwo,
      country: values.country,
      city: values.city,
      isNewCustomer: true
    });

    if (addCardResponse && addCardResponse.card_token) {
      // register event for card added to account for the first time
      trackAmplitudeEvent(AMPLITUDE_EVENTS.CARD_ADDED_TO_ACCOUNT);
      const createCustomerResponse = await makePayment({
        name: values.name,
        email: values.email,
        plan: values.plan,
        interval: values.interval,
        coupon: values.coupon,
        cardToken: addCardResponse.card_token
      });

      if (createCustomerResponse) {
        const { subscriptionId } = createCustomerResponse.msg;
        if (addCardResponse?.result === 'success') {
          const confirmation = await confirmPayment(subscriptionId, 'no');
          if (confirmation.result === 'success') {
            setMessage(confirmation.msg);

            // save subscription ID to local storage to be used on the setup page
            localStorage.setItem(SUBSCRIPTION_ID_KEY, subscriptionId);
            localStorage.removeItem('plan');

            // save organization
            localStorage.setItem(
              ORGANIZATION_ID,
              profile && (profile.organizations[0].account_id as string)
            );

            signIn({
              token,
              tokenType: 'Bearer',
              expiresIn: 720,
              authState: {
                ...auth(),
                newUser: false
              }
            });
            setTimeout(() => {
              // refetch organization data
              fetchOrganizationData();
              navigate(routes.SETUP);
            }, 1000);
          }
        }
      }
    }
  };

  return (
    <div className={'md:w-3/5 lg:w-full'}>
      {message ? (
        <div className={'h-[50vh] flex flex-col justify-center items-center text-center'}>
          <h4 className={'text-[#008160] text-lg'}>{message}</h4>
          <p className={'text-sm text-gray-400'}>Redirecting to dashboard...</p>
        </div>
      ) : loading ? (
        <div className={'h-[50vh] flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Creating a new subscription...Please wait</h5>
        </div>
      ) : (
        <div>
          <div className={'flex flex-col-reverse lg:grid lg:grid-cols-3 gap-5'}>
            <SelectedPackageCheckout selectedPlan={plan} intervalSelected={interval} />
            <div className={'lg:col-span-2 p-6 lg:p-10 bg-[#f5f5f5] drop-shadow-lg rounded-xl'}>
              <div>
                <div>
                  {message ? (
                    <SuccessMessage message={message} />
                  ) : (
                    <div>
                      <div className={'border border-gray-300 rounded-xl bg-[#E0EFE8] p-5 lg:p-8'}>
                        <h5 className={'font-semibold text-xl mb-5 text-center text-[#2E9A6E]'}>
                          Start Your 7-Day Free Trial!
                        </h5>
                        <form
                          onSubmit={(event) => {
                            event.preventDefault();
                            formik.handleSubmit();
                          }}
                          id={'first_time_payment_card-form'}
                          className={'grid grid-cols-1 gap-2'}>
                          <div className={'grid grid-cols-9 lg:grid-cols-8 lg:gap-4'}>
                            <div
                              className={'col-span-9 lg:col-span-2 flex flex-col justify-center'}>
                              <label className={'text-base'}>Name on card</label>
                            </div>
                            <input
                              type={'text'}
                              name={'name'}
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              autoFocus={true}
                              className="col-span-8 lg:col-span-5 w-full bg-white outline-0 p-2 rounded-md border border-gray-300 mt-1 flex items-center"
                            />
                          </div>
                          <p className={'text-sm text-red-500'}>
                            {formik.touched.name && formik.errors.name}
                          </p>
                          <div className={'grid grid-cols-9 lg:grid-cols-8 lg:gap-4'}>
                            <div
                              className={'col-span-9 lg:col-span-2 flex flex-col justify-center'}>
                              <label className={'text-base'}>Card number</label>
                            </div>
                            <div
                              className={
                                'col-span-8 lg:col-span-5 mt-1 bg-white border border-gray-300 rounded'
                              }>
                              <div className={'flex items-center gap-2'}>
                                <span className={'pl-2 pt-1'}>
                                  <i className="fi fi-rr-credit-card text-xl text-gray-700"></i>
                                </span>
                                <input
                                  type={'text'}
                                  name={'number'}
                                  maxLength={16}
                                  minLength={15}
                                  value={formik.values.number}
                                  onChange={formik.handleChange}
                                  className={'outline-0 w-full p-2'}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                'col-span-1 lg:col-span-1 flex justify-center lg:justify-start items-center'
                              }>
                              <span
                                className={`w-5 md:w-7 h-5 md:h-7 pt-1 flex justify-center items-center rounded-full border-2  ${
                                  formik.values.number.length >= 14 &&
                                  formik.values.number.length <= 16
                                    ? 'border-emerald-600'
                                    : 'border-gray-500'
                                }`}>
                                <i
                                  className={`fi fi-br-check text-[0.6rem] md:text-sm ${
                                    formik.values.number.length >= 14 &&
                                    formik.values.number.length <= 16
                                      ? 'text-emerald-600'
                                      : 'text-gray-500'
                                  }`}></i>
                              </span>
                            </div>
                          </div>
                          <div className={'hidden lg:grid lg:grid-cols-8 lg:gap-4'}>
                            <div
                              className={'col-span-4 lg:col-span-2 flex flex-col justify-center'}>
                              <label className={'text-base'}>
                                Expiration date <span className={'text-red-500'}>*</span>{' '}
                              </label>
                            </div>
                            <div
                              className={
                                'col-span-4 lg:col-span-5 w-full flex flex-col lg:flex-row gap-5 justify-between'
                              }>
                              <div className={'flex gap-3 items-center'}>
                                <input
                                  type={'text'}
                                  name={'expMonth'}
                                  value={formik.values.expMonth}
                                  onChange={formik.handleChange}
                                  placeholder={'MM'}
                                  minLength={2}
                                  maxLength={2}
                                  className={
                                    'outline-0 border border-gray-300 w-16 lg:w-16 rounded-md p-2 text-center'
                                  }
                                />
                                {'/'}
                                <input
                                  type={'text'}
                                  name={'expYear'}
                                  minLength={2}
                                  maxLength={2}
                                  placeholder={'YY'}
                                  value={formik.values.expYear}
                                  onChange={formik.handleChange}
                                  className={
                                    'outline-0 border border-gray-300 w-16 lg:w-16 rounded-md p-2 text-center'
                                  }
                                />
                              </div>
                              <div
                                className={'flex flex-col lg:flex-row gap-1 lg:gap-3 item-center'}>
                                <div className={'flex items-center'}>
                                  <label className={'text-base'}>CVV</label>
                                </div>
                                <input
                                  type={'text'}
                                  name={'cvc'}
                                  minLength={3}
                                  maxLength={
                                    formik.values.number.length === 14 ||
                                    formik.values.number.length === 16
                                      ? 3
                                      : 4
                                  }
                                  value={formik.values.cvc}
                                  onChange={formik.handleChange}
                                  placeholder={'CVC'}
                                  className={
                                    'outline-0 border border-gray-300 w-16  rounded-md p-2 text-center'
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className={'lg:hidden flex justify-between gap-1 lg:gap-4'}>
                            <div
                              className={
                                'col-span-4 w-full flex flex-col lg:flex-row lg:gap-5 justify-between'
                              }>
                              <div
                                className={'col-span-4 lg:col-span-2 flex flex-col justify-center'}>
                                <label className={'text-base'}>
                                  Expiration date <span className={'text-red-500'}>*</span>{' '}
                                </label>
                              </div>
                              <div className={'flex gap-2 items-center'}>
                                <input
                                  type={'text'}
                                  name={'expMonth'}
                                  value={formik.values.expMonth}
                                  onChange={formik.handleChange}
                                  placeholder={'MM'}
                                  minLength={2}
                                  maxLength={2}
                                  className={
                                    'outline-0 border border-gray-300 w-14 lg:w-20 rounded-md p-2 text-center'
                                  }
                                />
                                {'/'}
                                <input
                                  type={'text'}
                                  name={'expYear'}
                                  minLength={2}
                                  maxLength={2}
                                  placeholder={'YY'}
                                  value={formik.values.expYear}
                                  onChange={formik.handleChange}
                                  className={
                                    'outline-0 border border-gray-300 w-14 lg:w-20 rounded-md p-2 text-center'
                                  }
                                />
                              </div>
                            </div>
                            <div className={'flex flex-col lg:flex-row lg:gap-3 item-center'}>
                              <div className={'flex items-center'}>
                                <label className={'text-base'}>CVV</label>
                              </div>
                              <input
                                type={'text'}
                                name={'cvc'}
                                minLength={3}
                                maxLength={
                                  formik.values.number.length === 14 ||
                                  formik.values.number.length === 16
                                    ? 3
                                    : 4
                                }
                                value={formik.values.cvc}
                                onChange={formik.handleChange}
                                placeholder={'CVC'}
                                className={
                                  'outline-0 border border-gray-300 w-16 lg:w-28 rounded-md p-2 text-center'
                                }
                              />
                            </div>
                          </div>

                          <p className={'text-sm text-red-500'}>
                            {(formik.touched.number && formik.errors.number) ||
                              (formik.touched.expMonth && formik.errors.expMonth) ||
                              (formik.touched.expYear && formik.errors.expYear) ||
                              (formik.touched.cvc && formik.errors.cvc)}
                          </p>
                          <div className={'grid lg:grid-cols-8 lg:gap-4'}>
                            <div className={'lg:col-span-2 flex flex-col justify-center'}>
                              <label className={'text-base'}>
                                Email <span className={'text-red-500'}>*</span>{' '}
                              </label>
                            </div>

                            <input
                              type={'email'}
                              className={
                                'lg:col-span-5 p-2 w-full rounded border mt-1 border-gray-300'
                              }
                              name={'email'}
                              value={formik.values.email}
                              onChange={formik.handleChange}
                            />
                          </div>
                          <p className={'text-sm text-red-500'}>
                            {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                            {/*@ts-ignore*/}
                            {formik.touched.email && formik.errors.email}
                          </p>
                        </form>
                      </div>
                      <div className={'mt-5 flex justify-center items-center'}>
                        <button
                          type={'submit'}
                          onClick={() => formik.handleSubmit()}
                          disabled={loading}
                          className={`${
                            loading
                              ? 'bg-emerald-200 hover:bg-emerald-200'
                              : 'hover:bg-emerald-600 bg-[#2E9A6E]'
                          } px-5 py-3 lg:w-1/3 rounded font-semibold text-white lg:text-lg`}>
                          {loading ? 'creating Subscription...' : 'Get Started!'}
                        </button>
                      </div>

                      {errorMessage && <Error value={errorMessage} />}
                      {error && <Error value={error} />}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
function SelectedPackageCheckout({
  intervalSelected,
  selectedPlan
}: {
  intervalSelected: any;
  selectedPlan: IPlan;
}) {
  return (
    <div
      className={`flex flex-col justify-between rounded-md ${
        selectedPlan.value == 2
          ? 'bg-[#77DB89] relative'
          : selectedPlan.value == 3 || selectedPlan.value == 4
          ? 'bg-[#091226] text-white'
          : 'bg-[#B0F6C2]'
      } p-8`}>
      <div>
        {selectedPlan.value == 2 && (
          <span
            className={
              'w-full rounded-t-md absolute top-0 left-0 h-[0.625rem] bg-[#091226]'
            }></span>
        )}
        <div className={'flex gap-2'}>
          <h3 className={'font-extrabold text-[1.75rem]'}>{plan[selectedPlan.value - 1].name}</h3>
        </div>

        <div className={'mt-5 mb-10 flex items-end gap-4 leading-none'}>
          <span className={'flex items-end'}>
            <span className={'font-extrabold text-[2.8125rem]'}>$</span>
            <span className={'font-extrabold text-[2.8125rem]'}>
              {intervalSelected?.value === 'month'
                ? plan[selectedPlan.value - 1].monthlyPrice
                : plan[selectedPlan.value - 1].yearlyPrice * 12}
            </span>
          </span>
          <span>/{intervalSelected?.value}</span>
        </div>

        <div className={'mb-2'}>
          <div className={'px-0 py-3 w-full '}>
            {selectedPlan.value == 2 ? (
              <span className={'font-black text-[0.875rem]'}>Track up to $250,000 per month</span>
            ) : selectedPlan.value == 3 ? (
              <span className={'font-black text-[0.875rem]'}>Track up to $500,000 per month</span>
            ) : selectedPlan.value == 4 ? (
              <span className={'font-black text-[0.875rem]'}>Track up to $1,000,000 per month</span>
            ) : (
              <span className={'font-black text-[0.875rem]'}>Track up to $50,000 per month</span>
            )}
          </div>
        </div>

        <div className={'mt-5'}>
          {/*<h5 className={'font-semibold text-base text-black'}>Package includes:</h5>*/}
          <div>
            {selectedPlan.value == 2 ? (
              <ul className={'mt-2 grid gap-2'}>
                {businessPlanItems &&
                  businessPlanItems.map((item, index) => (
                    <PackageItem color={'text-[#091226]'} key={index} value={item} />
                  ))}
                {intervalSelected.value === 'year' && (
                  <PackageItem
                    color={'text-[#091226]'}
                    value={'Month 1 website audit by Conversion Rate experts'}
                  />
                )}
              </ul>
            ) : selectedPlan.value == 3 || selectedPlan.value == 4 ? (
              <ul className={'mt-2 grid gap-2'}>
                {premiumPlanItems &&
                  premiumPlanItems.map((item, index) => (
                    <PackageItem color={'text-white'} key={index} value={item} />
                  ))}
              </ul>
            ) : (
              <ul className={'mt-2 grid gap-2'}>
                {basicPlanItems &&
                  basicPlanItems.map((item, index) => (
                    <PackageItem color={'text-[#091226]'} key={index} value={item} />
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
