import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CancelSubscription from '../../AccountSubscription/CancelSubscription';
import { useContext, useRef, useState } from 'react';
import { IWebsiteSubscription } from '../../../interfaces/subscription';
import { ORGANIZATION_ID, SUBSCRIPTION_ID_KEY } from '../../../constants';
import { useAuthUser } from 'react-auth-kit';
import { routes } from '../../../constants/routes';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import Modal from '../../../components/global/ui/Modal';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import { useSubscription } from '../../../pages/main/AccountSubscription/useSubscription';

export default function PendingSubscriptionListCard({ data }: { data: IWebsiteSubscription }) {
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<IWebsiteSubscription | null>(null);

  const navigate = useNavigate();
  const auth = useAuthUser();
  const { profile } = useContext(OrganizationContext);
  const isSuperUser = auth()?.super_user === 1;
  const [loading, setLoading] = useState<boolean>(false);

  const { cancelSubscription, errorMessage } = useSubscription();
  const [message, setMessage] = useState<string | null>(null);
  const [messageShow, setMessageShow] = useState<boolean>(false);

  const { fetchOrganizationData } = useContext(OrganizationContext);

  const ref = useRef(false);
  const handleCancelSubscription = async (subscriptionId: string) => {
    setMessageShow(true);
    setLoading(true);
    setMessage(null);
    const response = await cancelSubscription({
      subscriptionId: subscriptionId
    });
    if (response.result == 'success') {
      setMessage(response.msg);
      setLoading(false);
      setTimeout(() => {
        // refetch organization data
        fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
      }, 2000);
    }
    setLoading(false);
    // setMessageShow(false);
  };

  // This is the function called by the "Cancel button" in your company's webpage
  function cancel({ subscriptionId }: { subscriptionId: string }) {
    setLoading(false);
    if (process.env.NODE_ENV === 'production' && !ref.current && typeof window !== 'undefined') {
      window.profitwell('start', {
        user_email: auth()?.email
      });
      ref.current = true;
    }

    window
      .profitwell('init_cancellation_flow', {
        subscription_id: subscriptionId
      })
      .then((result: any) => {
        // User does not proceed to cancel
        if (result.status === 'retained' || result.status === 'aborted') {
          setLoading(false);
          return;
        }
        // User proceeds to cancel subscription

        handleCancelSubscription(subscriptionId);
      });
  }

  const navigateToAddWebsite = () => {
    // save subscription ID to local storage to be used on the setup page
    localStorage.setItem(SUBSCRIPTION_ID_KEY, data.subscription_id);
    navigate(routes.SETUP);
  };

  return (
    <>
      {isSuperUser && cancelOpen && selected && (
        <CancelSubscription
          subscriptionId={selected.subscription_id}
          subscriptionID={selected.id as string}
          website={selected}
          closeModal={() => setCancelOpen(false)}
        />
      )}
      {(loading || message) && messageShow && (
        <Modal closeModal={() => setMessageShow(false)} title={''} width={'lg:w-1/3'}>
          {message ? (
            <SuccessMessage message={message as string} />
          ) : (
            <div className={'flex flex-col justify-center items-center p-5'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <h5 className={'mt-2 text-sm'}>Cancelling subscription...Please wait</h5>
            </div>
          )}
        </Modal>
      )}
      <div
        className={
          'border border-gray-200 bg-white hover:border-gray-400 cursor-default w-full rounded-md p-4'
        }>
        <div className={'flex gap-3 md:gap-5'}>
          <div className={'w-full flex flex-col justify-between'}>
            <div>
              <div className={'flex justify-between'}>
                <div className={'flex flex-col lg:flex-row gap-2 lg:items-center'}>
                  <div>
                    <span
                      className={`rounded-full px-2 py-1 text-xs font-medium border ${
                        data?.status == 'active'
                          ? 'bg-emerald-100 text-[#008160] border-emerald-100'
                          : data?.status == 'cancelled'
                          ? 'bg-red-100 text-red-500 border-red-100'
                          : 'bg-amber-100 text-amber-600 border-amber-100'
                      } uppercase`}>
                      {data?.status}
                    </span>
                  </div>
                  <div className={'flex gap-3'}>
                    <span className={'font-medium'}>{data.id}</span>
                    <h5 className={'text-base text-[#008160]'}>{data.plan_name} Plan</h5>
                  </div>
                  {auth()?.super_user == 1 && (
                    <>
                      <span className={'hidden lg:flex w-1 h-1 rounded-full bg-gray-400'}></span>

                      <div className={'flex gap-2'}>
                        <span>
                          <i className="fi fi-rr-user text-sm items-center"></i>
                        </span>
                        <span className={'text-sm text-gray-700'}>
                          {data.created_by?.full_name} ({data.created_by?.email})
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className={'hidden lg:flex'}>
                  {!(data.status == 'paused' || data.status == 'cancelled') && (
                    <div className={'w-full flex justify-between gap-2'}>
                      {/*{auth()?.super_user == 0 && (*/}
                      <button
                        type={'button'}
                        onClick={navigateToAddWebsite}
                        className={
                          'rounded bg-[#008160] hover:bg-emerald-600 text-xs text-white px-3 py-2'
                        }>
                        Add website
                      </button>
                      {/*)}*/}
                    </div>
                  )}
                  {/*{data && data.status.toLowerCase() !== 'active' && (*/}
                  {/*  <button*/}
                  {/*    type={'button'}*/}
                  {/*    onClick={navigateToAddWebsite}*/}
                  {/*    className={*/}
                  {/*      'rounded bg-[#008160] hover:bg-emerald-600 text-xs text-white px-3 py-2'*/}
                  {/*    }>*/}
                  {/*    Reactivate*/}
                  {/*  </button>*/}
                  {/*)}*/}
                </div>
              </div>
              <div className={'flex flex-col md:flex-row justify-between md:items-end'}>
                <div
                  className={
                    'flex flex-row justify-between lg:justify-start gap-2 md:gap-5 lg:gap-8 mt-3 lg:mt-1'
                  }>
                  <div>
                    <h6 className={'text-xs'}>PERIOD</h6>
                    <p className={'lg:mt-1 font-medium text-sm capitalize'}>{data.plan_interval}</p>
                  </div>
                  <div>
                    <h6 className={'text-xs'}>START DATE</h6>
                    <p className={'lg:mt-1 font-medium text-sm'}>
                      {moment(data.plan_period_start).format('ll')}
                    </p>
                  </div>
                  <div>
                    <h6 className={'text-xs'}>END DATE</h6>
                    <p className={'lg:mt-1 font-medium text-sm'}>
                      {moment(data.plan_period_end).format('ll')}
                    </p>
                  </div>
                  <div>
                    <h6 className={'text-xs'}>NEXT AMOUNT</h6>
                    <p className={'lg:mt-1 font-medium text-sm'}>${data.paid_amount}</p>
                  </div>
                </div>
                <div
                  className={
                    'flex justify-between md:items-center lg:items-start lg:justify-end gap-3 mt-3 lg:mt-0'
                  }>
                  {!(data.status == 'paused' || data.status == 'cancelled') &&
                    profile.profile_details?.account_holder == 1 && (
                      <button
                        type={'button'}
                        onClick={() => {
                          setSelected(data);
                          if (isSuperUser) {
                            setCancelOpen(true);
                          } else cancel({ subscriptionId: data.subscription_id });
                        }}
                        className={`text-[#008160] hover:underline text-sm md:px-3 lg:px-0 ${
                          loading ? 'loading text-gray-400' : ''
                        }`}>
                        {loading ? 'Cancelling' : 'Cancel'}
                      </button>
                    )}
                  {/*{auth()?.super_user == 0 && (*/}
                  {!(data.status == 'paused' || data.status == 'cancelled') && (
                    <button
                      type={'button'}
                      onClick={navigateToAddWebsite}
                      className={
                        'flex lg:hidden rounded bg-[#008160] hover:bg-emerald-600 text-xs text-white px-3 py-2'
                      }>
                      Add website
                    </button>
                  )}
                  {/*)}*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
