import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../../util/global';
import { IOnboarding } from '../../../interfaces/website';
import OnboardingProgressBar from './OnboardingProgressBar';
import { useEditWebsite } from '../../../pages/main/Websites/useEditWebsite';
import Error from '../../../components/global/ui/alerts/Error';
import LoadingSpinnerMini from '../../../components/global/LoadingSpinnerMini';
import industriesData from '../../../data/industries.json';
import { routes } from '../../../constants/routes';
import { websiteGoals } from '../../../constants';
import IndustriesPopover from '../../../components/global/ui/popovers/IndustriesPopover';

export interface IGoal {
  id: number;
  name: string;
  description: string;
  icon: string;
}

export default function PlatformType({ changeStep }: IOnboarding) {
  const [selectedGoal, setSelectedGoal] = useState<IGoal | null>(null);
  const [selectedIndustry, setSelectedIndustry] = useState<string | null>(null);
  const [industryOptionOpen, setIndustryOptionOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);

  const { editWebsite, loading, errorMessage } = useEditWebsite();
  const query = useQuery();
  const navigate = useNavigate();

  const handleSelectWebsiteType = async () => {
    setMessage(null);
    const response = await editWebsite({
      siteId: query && (query.get('siteId') as string),
      ecommerce: selectedGoal?.id,
      industry: selectedIndustry as string,
      url: query && (query.get('url') as string)
    });

    if (response?.result === 'success') {
      setMessage(response.msg);
      changeStep(3);
    }
  };

  const goBack = () => {
    if (
      query.get('step') &&
      (query.get('step') === '2' || query.get('step') === '3') &&
      query.get('url')
    ) {
      navigate(routes.DASHBOARD);
    }
  };

  return (
    <>
      <div className="lg:w-[70%] w-full">
        <OnboardingProgressBar step={2} />

        <div className="mb-3">
          <p className="text-3 black my-8 text-align-center">
            What is the main goal of your website?
          </p>

          <div>
            <div className={'grid md:grid-cols-2 gap-3'}>
              {websiteGoals &&
                websiteGoals.map((item) => (
                  <div
                    key={item.id}
                    onClick={() => setSelectedGoal(item)}
                    className={`border rounded-lg shadow p-5 ${
                      selectedGoal?.id == item.id
                        ? 'bg-[#008160] text-white hover:text-white'
                        : 'hover:bg-[#E2FDEC]'
                    } cursor-pointer`}>
                    <div className={'grid gap-2'}>
                      <div className={'flex justify-between items-center'}>
                        <img src={item.icon} width={40} alt={''} className={'w-[10%] md:w-[15%]'} />
                        {item.id == 2 && (
                          <span
                            className={
                              'rounded-full bg-gradient-to-tr from-emerald-400 to-sky-400 px-3 py-1 text-xs text-white'
                            }>
                            Coming soon
                          </span>
                        )}
                      </div>
                      <div>
                        <p className={'font-bold text-sm'}>{item.name}</p>
                        <p className={'text-xs mt-2'}>{item.description}</p>
                      </div>
                      <div className={'mt-3'}>
                        <button
                          type={'button'}
                          onClick={() => setSelectedGoal(item)}
                          className={`mt-1 ${
                            selectedGoal?.id == item.id
                              ? 'border-white text-white'
                              : 'text-[#008160] border-[#008160] '
                          } border rounded-md px-3 py-1 text-xs font-medium`}>
                          {selectedGoal?.id == item.id && selectedGoal.id == 1 ? (
                            <span>Selected</span>
                          ) : (
                            <span>Select</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className={'my-4'}>
              <label className={'text-sm'}>What industry is this website?</label>
              <div className="relative w-full mt-1">
                <button
                  type={'button'}
                  onClick={() => {
                    setIndustryOptionOpen(!industryOptionOpen);
                  }}
                  className={
                    'rounded w-full px-3 py-2 flex items-center justify-between gap-5 border border-transparent hover:border-emerald-300 bg-white drop-shadow text-gray-500 text-sm'
                  }>
                  {selectedIndustry ? (
                    <>
                      <span>{selectedIndustry}</span>
                      <span>
                        <i
                          className={`fi ${
                            industryOptionOpen ? 'fi-sr-caret-up' : 'fi-sr-caret-down'
                          } text-lg`}></i>
                      </span>
                    </>
                  ) : (
                    <>
                      <span>Choose Industry</span>
                      <span>
                        <i
                          className={`fi ${
                            industryOptionOpen ? 'fi-sr-caret-up' : 'fi-sr-caret-down'
                          } text-lg`}></i>
                      </span>
                    </>
                  )}
                </button>
                {industryOptionOpen && (
                  <IndustriesPopover
                    data={industriesData}
                    setSelected={setSelectedIndustry}
                    hideData={() => setIndustryOptionOpen(false)}
                    show={industryOptionOpen}
                    onClickOutside={() => setIndustryOptionOpen(false)}
                  />
                )}
              </div>
            </div>
            <div className={'flex justify-center mt-5'}>
              <div className={'w-full md:w-[60%] grid gap-3'}>
                <button
                  disabled={!selectedGoal || selectedGoal?.id == 2 || !selectedIndustry}
                  type={'button'}
                  onClick={handleSelectWebsiteType}
                  className={`rounded-md px-3 py-3 ${
                    selectedGoal?.id == 1 && selectedIndustry
                      ? 'bg-[#008160] hover:bg-emerald-600 text-white'
                      : 'bg-[#F6F6F6] text-gray-600'
                  } w-full font-medium text-sm`}>
                  {loading ? (
                    <div className={'flex justify-center'}>
                      <LoadingSpinnerMini color={'text-white'} />
                    </div>
                  ) : (
                    'Next'
                  )}
                </button>
                <button
                  type={'button'}
                  onClick={goBack}
                  className={'px-3 py-2 bg-transparent text-[#008160] w-full font-medium text-sm'}>
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={'flex flex-col justify-center items-center'}>
          {errorMessage && <Error value={errorMessage as string} />}
        </div>
      </div>
    </>
  );
}
