import { useState } from 'react';
import UpgradeSubscription from '../AccountSubscription/UpgradeSubscription';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import moment from 'moment';
import { IWebsiteSubscription } from '../../interfaces/subscription';

export default function ExceededLimitWebsiteListCard({ data }: { data: IWebsiteSubscription }) {
  const [selected, setSelected] = useState<IWebsiteSubscription | null>(null);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const auth = useAuthUser();
  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);

  const redirectToDashboard = (event: any) => {
    /*
     * Checks if user clicks edit, upgrade or cancel buttons to avoid redirection to dashboard
     *  */
    if (data.website?.verified == 1 && !event.target.className.includes('edit')) {
      window.location.replace(
        `${process.env.REACT_APP_API_URL}/index.php?module=Login&action=auth&hash=${
          auth()?.hash
        }&key=${token}&idSite=${data.idsite}&account_id=${data.account_id}`
      );
    }
  };

  return (
    <>
      {upgradeOpen && selected && (
        <UpgradeSubscription
          currentPlan={data.plan}
          currentInterval={data.plan_interval}
          subscriptionId={data.subscription_id}
          closeModal={() => setUpgradeOpen(false)}
        />
      )}
      <div
        className={`border rounded-md border-gray-200 ${
          data.website?.verified ? 'cursor-pointer' : 'cursor-default'
        } hover:border-gray-400 bg-white hover:shadow p-3 md:p-3`}>
        <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 gap-2 lg:gap-5'}>
          <div onClick={redirectToDashboard} className={'lg:col-span-4 flex gap-2'}>
            <div>
              <img
                className={'rounded w-6 h-6 object-contain'}
                src={`https://www.google.com/s2/favicons?domain=${data.website?.main_url}&sz=128`}
                alt={''}
              />
            </div>
            <div className={'flex justify-between'}>
              <div className={'w-full'}>
                <div className={'flex gap-2 items-center flex-wrap'}>
                  <h5 className={'font-medium text-base lg:text-sm capitalize truncate'}>
                    {data.website?.name}
                  </h5>
                  {data.website?.ecommerce === 1 && (
                    <>
                      <span className={'w-1 h-1 rounded-full bg-gray-400'}></span>
                      <span className={'font-normal text-sm lg:text-sm text-gray-500'}>
                        e-commerce
                      </span>
                    </>
                  )}
                </div>
                <div className={'flex gap-1 my-1'}>
                  <span>
                    <i className="fi fi-rr-link text-xs"></i>
                  </span>
                  <span className={'text-sm md:text-sm truncate'}>{data.website?.main_url}</span>
                </div>
                {data.website?.verified ? (
                  <div>
                    <h6 className={'text-sm'}>
                      <span>Verified: </span>
                      <span>{moment(data.website?.verified_date).format('lll')}</span>
                    </h6>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div onClick={redirectToDashboard}>
            <div className={'flex lg:flex-col gap-2 items-start md:justify-end lg:justify-start'}>
              <span
                className={`rounded-full px-2 py-1 leading-none text-xs font-medium border ${
                  data.status == 'active'
                    ? 'bg-emerald-100 text-[#008160] border-emerald-100'
                    : data.status == 'cancelled'
                    ? 'bg-red-100 text-red-500 border-red-100'
                    : 'bg-amber-100 text-amber-600 border-amber-100'
                } uppercase`}>
                {data.status}
              </span>
              <span
                className={
                  'rounded-full px-2 py-1 leading-none text-xs bg-white text-[#008160] border border-[#008160] capitalize'
                }>
                {data.plan_name}
              </span>
            </div>
          </div>
          <div onClick={redirectToDashboard} className={'flex justify-center'}>
            {data.website?.verified == 1 ? (
              <span className={'text-[#008160] hidden md:flex'}>
                <i className="fi fi-ss-badge-check text-xl md:text-xl"></i>
              </span>
            ) : (
              <span className={'text-gray-500 hidden md:flex'}>
                <i className="fi fi-rr-badge-check text-gray-400 text-xl md:text-xl"></i>
              </span>
            )}
          </div>
          <div
            className={'flex gap-2 justify-between md:justify-end lg:justify-between items-start'}>
            <button
              type={'button'}
              onClick={() => {
                setSelected(data);
                setUpgradeOpen(true);
              }}
              className={
                'rounded flex gap-2 justify-center items-center text-xs px-3 py-2 bg-[#008160] hover:bg-emerald-600 text-white'
              }>
              <span className={'pt-1'}>
                <i className="fi fi-rr-rocket-lunch text-xs"></i>
              </span>
              <span>Upgrade</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
