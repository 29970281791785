import { Link, useNavigate } from 'react-router-dom';
import RightSectionPlaceholder from '../../../layouts/authentication/RightSectionPlaceholder';
import AuthFormHeader from '../../../components/form/AuthFormHeader';
import InputWithoutLabel from '../../../components/input/InputWithoutLabel';
import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { usePasswordReset } from './usePasswordReset';
import TopHeader from '../../../components/global/TopHeader';
import Error from '../../../components/global/ui/alerts/Error';
import { useEffect, useState } from 'react';
import Success from '../../../components/global/ui/alerts/Success';
import { useAuthHeader } from 'react-auth-kit';
import { routes } from '../../../constants/routes';

export default function ResetPassword() {
  const { getResetCode, loading, errorMessage } = usePasswordReset();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  useEffect(() => {
    document.title = 'Heatmap Reset Password';
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter a valid email address').required('Email address is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: async (values) => handlePasswordResetRequest(values)
  });

  const handlePasswordResetRequest = async (values: FormikValues) => {
    localStorage.setItem('email', values.email);

    const response = await getResetCode(values.email);
    if (response.result === 'success') {
      setSuccessMessage(response.msg || response.message);
      setTimeout(() => {
        navigate(routes.VERIFY_EMAIL_FOR_PASSWORD);
      }, 1000);
    }
  };

  return (
    <div>
      <main className="main-container">
        <TopHeader />

        <div className="grid lg:grid-cols-2 sides-container">
          <section className="pt-[10vh] px-5 flex flex-col items-center">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}
              className="w-full md:w-1/2 flex flex-col">
              <AuthFormHeader
                title={'Reset Password'}
                description={'Enter your email to reset your password.'}
              />
              <InputWithoutLabel
                name={'email'}
                type={'email'}
                value={formik.values.email}
                changeHandler={formik.handleChange}
                placeholder={'Your email'}
                autoFocus={true}
              />

              <p className={'text-sm text-red-500'}>
                {formik.touched.email && formik.errors.email}
              </p>

              <div className={'mt-5 w-full'}>
                <PrimaryButton
                  disabled={loading}
                  title={loading ? 'Requesting reset...' : 'Request reset'}
                  handler={() => formik.handleSubmit()}
                />
              </div>

              {errorMessage && <Error value={errorMessage} />}
              {successMessage && <Success value={successMessage} />}

              <p className="text-sm text-center mt-2">
                {token ? (
                  <Link to={routes.DASHBOARD} className="text-secondary">
                    Go back to dashboard
                  </Link>
                ) : (
                  <Link to={routes.LOGIN} className="text-secondary">
                    Go back to login
                  </Link>
                )}
              </p>
            </form>
          </section>
          <RightSectionPlaceholder />
        </div>
      </main>
    </div>
  );
}
