import PrimaryButton from '../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useEditWebsite } from '../../pages/main/Websites/useEditWebsite';
import { useContext, useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import { IWebsiteDeleteModal } from '../../interfaces/website';
import { brands, ORGANIZATION_ID, websiteSettingsTabs } from '../../constants';
import { useAuthUser } from 'react-auth-kit';
import { OrganizationContext } from '../../contexts/OrganizationContext';

export default function EditWebsite({ website, closeModal }: IWebsiteDeleteModal) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Website name is required'),
    url: Yup.string().required('URL is required'),
    isEcommerce: Yup.boolean()
  });

  const { editWebsite, loading, errorMessage } = useEditWebsite();
  const { fetchOrganizationData } = useContext(OrganizationContext);

  const [message, setMessage] = useState<string | null>(null);
  const [tab, setTab] = useState<number>(1);

  const auth = useAuthUser();

  const formik = useFormik({
    initialValues: {
      name: website.name || '',
      url: website.main_url || '',
      ecommerce: website.ecommerce || 0,
      platform: website.platform || 0,
      frontendDebugEnabled: website.frontend_debug == 1,
      backendDebugEnabled: website.backend_debug == 1,
      recordingEnabled: website.enable_recording == 1,
      forceScreenshot: website.force_screenshot == 1
    },
    validationSchema,
    onSubmit: async (values) => handleUpdateWebsite(values)
  });

  const handleUpdateWebsite = async (values: FormikValues) => {
    setMessage(null);
    const response = await editWebsite({
      name: values.name,
      siteId: website.idsite.toString(),
      url: values.url,
      platform: values.platform,
      ecommerce: values.ecommerce,
      frontend_debug: values.frontendDebugEnabled ? 1 : 0,
      backend_debug: values.backendDebugEnabled ? 1 : 0,
      enable_recording: values.recordingEnabled ? 1 : 0,
      force_screenshot: values.forceScreenshot ? 1 : 0
    });

    if (response?.result === 'success') {
      setMessage(response.msg);
      // refetch organization data
      fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Update website'} width={'w-full md:w-1/2 lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Updating your website...Please wait</h5>
        </div>
      ) : (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            formik.handleSubmit();
          }}>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div className={'grid grid-cols-2 gap-2 my-2'}>
                {websiteSettingsTabs &&
                  auth()?.super_user == 1 &&
                  websiteSettingsTabs.map((item, index) => (
                    <button
                      type={'button'}
                      onClick={() => setTab(item.id)}
                      key={index}
                      className={`${
                        tab === item.id
                          ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                          : 'border-transparent'
                      } py-2 border-b-2 hover:border-gray-300 font-medium text-sm text-center`}>
                      <span className={'text-xs md:text-sm text-center'}>{item.name}</span>
                    </button>
                  ))}
              </div>

              <div className={'grid gap-2'}>
                <div>
                  {tab && tab == 1 && (
                    <div>
                      <>
                        {website.verified === 1 ? (
                          <span className={'flex items-start gap-2 text-[#008160] pt-2'}>
                            <i className="fi fi-ss-badge-check text-lg"></i>
                            <span>verified</span>
                          </span>
                        ) : (
                          <span className={'flex items-start gap-2 text-gray-500 pt-2'}>
                            <i className="fi fi-ss-badge-check text-lg"></i>
                            <span>pending verification</span>
                          </span>
                        )}
                      </>
                      <div>
                        <label className={'text-sm'}>Website name</label>
                        <input
                          type={'text'}
                          name={'name'}
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          autoFocus={true}
                          className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                        />
                        <p className={'text-sm text-red-500'}>
                          {formik.touched.name && formik.errors.name}
                        </p>
                      </div>
                      <div>
                        <label className={'text-sm'}>Enter a valid URL</label>
                        <input
                          type={'text'}
                          name={'url'}
                          value={formik.values.url}
                          onChange={formik.handleChange}
                          disabled={website.verified === 1 && auth()?.super_user == 0}
                          title={
                            website.verified === 1 && auth()?.super_user == 0
                              ? 'Verified website URL cannot be changed'
                              : ''
                          }
                          className={`w-full ${
                            website.verified === 1 && auth()?.super_user == 0 ? 'bg-gray-200' : ''
                          } outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center`}
                        />
                        <p className={'text-sm text-red-500'}>
                          {formik.touched.url && formik.errors.url}
                        </p>
                      </div>

                      <div>
                        <label className={'text-sm'}>Select website goal</label>
                        <select
                          className={`px-2 py-3 w-full rounded border text-sm border-gray-300`}
                          name={'ecommerce'}
                          value={formik.values.ecommerce}
                          onChange={formik.handleChange}>
                          <option value={0}>--Select--</option>
                          <option value={1}>Ecommerce</option>
                          {/*<option value={2}>Lead generation</option>*/}
                        </select>
                        <p className={'text-sm text-red-500'}>
                          {formik.touched.ecommerce && formik.errors.ecommerce}
                        </p>
                      </div>
                      <div>
                        <label className={'text-sm'}>Select the platform</label>
                        <select
                          className={`px-2 py-3 w-full rounded border text-sm border-gray-300`}
                          name={'platform'}
                          value={formik.values.platform}
                          onChange={formik.handleChange}>
                          <option value={0}>--Select--</option>
                          {brands &&
                            brands.map((item, index) => (
                              <option key={index} value={item.key}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                        <p className={'text-sm text-red-500'}>
                          {formik.touched.platform && formik.errors.platform}
                        </p>
                      </div>
                    </div>
                  )}
                  {tab && tab == 2 && (
                    <div>
                      {auth()?.super_user == 1 && (
                        <div className={'grid gap-4 mt-2'}>
                          <div>
                            <div className={'flex gap-2'}>
                              <input
                                id={'frontendDebugEnabled'}
                                type={'checkbox'}
                                name={'frontendDebugEnabled'}
                                checked={formik.values.frontendDebugEnabled}
                                onChange={formik.handleChange}
                                className={`outline-0 w-5 h-5 rounded border border-gray-300 accent-[#008160]`}
                              />
                              <label htmlFor={'frontendDebugEnabled'} className={'text-sm'}>
                                Enable frontend debugging
                              </label>
                            </div>
                            <p className={'text-sm text-red-500'}>
                              {formik.touched.frontendDebugEnabled &&
                                formik.errors.frontendDebugEnabled}
                            </p>
                          </div>
                          <div>
                            <div className={'flex gap-2'}>
                              <input
                                id={'backendDebugEnabled'}
                                type={'checkbox'}
                                name={'backendDebugEnabled'}
                                checked={formik.values.backendDebugEnabled}
                                onChange={formik.handleChange}
                                className={`outline-0 w-5 h-5 rounded border border-gray-300 accent-[#008160]`}
                              />
                              <label htmlFor={'backendDebugEnabled'} className={'text-sm'}>
                                Enable backend debugging
                              </label>
                            </div>
                            <p className={'text-sm text-red-500'}>
                              {formik.touched.backendDebugEnabled &&
                                formik.errors.backendDebugEnabled}
                            </p>
                          </div>
                          <div>
                            <div className={'flex gap-2'}>
                              <input
                                id={'recordingEnabled'}
                                type={'checkbox'}
                                name={'recordingEnabled'}
                                checked={formik.values.recordingEnabled}
                                onChange={formik.handleChange}
                                className={` outline-0 w-5 h-5 rounded border border-gray-300 accent-[#008160]`}
                              />
                              <label htmlFor={'recordingEnabled'} className={'text-sm'}>
                                Enable Recording
                              </label>
                            </div>
                            <p className={'text-sm text-red-500'}>
                              {formik.touched.recordingEnabled && formik.errors.recordingEnabled}
                            </p>
                          </div>
                          <div>
                            <div className={'flex gap-2'}>
                              <input
                                id={'forceScreenshot'}
                                type={'checkbox'}
                                name={'forceScreenshot'}
                                checked={formik.values.forceScreenshot}
                                onChange={formik.handleChange}
                                className={` outline-0 w-5 h-5 rounded border border-gray-300 accent-[#008160]`}
                              />
                              <label htmlFor={'forceScreenshot'} className={'text-sm'}>
                                Force screenshot
                              </label>
                            </div>
                            <p className={'text-sm text-red-500'}>
                              {formik.touched.forceScreenshot && formik.errors.forceScreenshot}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className={'mt-2'}>
                  <PrimaryButton
                    disabled={loading}
                    title={loading ? 'Updating website...' : 'Update website'}
                    handler={() => formik.handleSubmit()}
                  />
                </div>
                {errorMessage && <Error value={errorMessage} />}
              </div>
            </div>
          )}
        </form>
      )}
    </Modal>
  );
}
