import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import DeleteCoupon from './DeleteCoupon';
import DeletePromo from './DeletePromo';
import UpdateCoupon from './UpdateCoupon';
import { ICoupon } from '../../../interfaces/coupon';
import UpdatePromo from './UpdatePromo';

export default function CouponListCard({
  data,
  toggle
}: {
  data: ICoupon;
  toggle: (data: ICoupon) => void;
}) {
  const [selected, setSelected] = useState<ICoupon | null>(null);
  const [activeStatus, setActiveStatus] = useState<boolean>(Boolean(data.status === 'active'));

  const auth = useAuthUser();

  const [editOpen, setEditOpen] = useState<boolean>(false);

  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const openEditModal = () => setEditOpen(true);
  const closeEditModal = () => setEditOpen(false);

  const openCancelModal = () => setCancelOpen(true);
  const closeCancelModal = () => setCancelOpen(false);

  // console.log(data);

  return (
    <>
      {editOpen && selected && selected.uuid.startsWith('promo') && (
        <UpdatePromo coupon={selected} closeModal={closeEditModal} />
      )}
      {editOpen && selected && !selected.uuid.startsWith('promo') && (
        <UpdateCoupon coupon={selected} closeModal={closeEditModal} />
      )}

      {cancelOpen && selected && selected.uuid.startsWith('promo') && (
        <DeletePromo promoId={data.uuid} closeModal={closeCancelModal} />
      )}
      {cancelOpen && selected && !selected.uuid.startsWith('promo') && (
        <DeleteCoupon couponId={data.uuid} closeModal={closeCancelModal} />
      )}
      <div
        className={
          'border rounded-md border-gray-200 hover:border-gray-300 bg-white hover:shadow p-3 md:p-3'
        }>
        <div className={'grid grid-cols-1 lg:grid-cols-7 gap-2 lg:gap-5'}>
          <div className={'col-span-2'}>
            <div className={'flex justify-between'}>
              <div>
                <div className={'flex gap-2 items-center'}>
                  <h5 className={'font-medium text-base lg:text-base capitalize'}>
                    {data.coupon_id}
                  </h5>
                </div>
                <div className={'flex gap-2 my-1'}>
                  <h5 className={'text-sm md:text-sm'}>{data.title}</h5>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h5 className={'font-medium text-base lg:text-base capitalize'}>
              {data.is_percentage === 'No' && 'USD '}
              {data.amount}
              {data.is_percentage === 'Yes' && '%'}
            </h5>
          </div>
          <div className={'col-span-2'}>
            <h5 className={'text-sm md:text-sm'}>
              <span className={'font-medium'}>Begins:</span> {data.begin}
            </h5>
            <h5 className={'text-sm md:text-sm'}>
              <span className={'font-medium'}>Expires:</span> {data.expiry}
            </h5>
          </div>
          <div>
            <div className={'flex'}>
              <span
                className={`rounded-full px-2 py-1 leading-none text-xs font-medium ${
                  data.status == 'active'
                    ? 'bg-emerald-100 text-[#008160]'
                    : data.status == 'paused'
                    ? 'bg-amber-100 text-amber-500'
                    : data.status == 'cancelled'
                    ? 'bg-red-100 text-red-500'
                    : ''
                } uppercase`}>
                {data.status}
              </span>
            </div>
          </div>
          <div className={'flex gap-2 justify-end items-start'}>
            <div className={'flex gap-2 items-center'}>
              {auth()?.super_user == 1 && (
                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input
                    type="checkbox"
                    name="toggle"
                    id="toggle"
                    checked={activeStatus}
                    onChange={() => toggle(data)}
                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                  />
                  <label
                    htmlFor="toggle"
                    className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                </div>
              )}

              {auth()?.super_user == 1 && (
                <button
                  title={'Delete Coupon'}
                  type={'button'}
                  onClick={() => {
                    setSelected(data);
                    openCancelModal();
                  }}
                  className={` rounded px-2 py-1 flex items-start gap-2 border border-transparent hover:border-red-500 bg-red-50 text-red-500 text-sm flex gap-2`}>
                  <span>
                    <i className="fi fi-rr-trash text-sm"></i>
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
