import heatmapLogo from '../../assets/heatmap-logo.svg';

export default function TopHeader() {
  return (
    <nav className="bg-white px-5 lg:px-24 py-3 shadow flex justify-center lg:justify-between items-center">
      <div className="">
        <div id="logo-link" className={'flex items-center gap-3'}>
          <img src={heatmapLogo} alt="" className={'w-full md:w-full lg:w-full'} />
        </div>
      </div>
    </nav>
  );
}
