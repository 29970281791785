import { Link } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import ActiveSubscriptionListCard from '../../../feature/Dashboard/Subscription/ActiveSubscriptionListCard';
import PendingUnusedSubscription from '../../../layouts/dashboard/PendingUnusedSubscription';
import Layout from '../../../layouts/global/Layout';
import { useAuthUser } from 'react-auth-kit';
import { ISubscription } from '../../../interfaces/subscription';
import { useAnalytics } from './useAnalytics';
import OnboardingSummary from '../../../feature/Dashboard/Analytics/OnboardingSummary';
import { IAnalytic, IClientsPendingCancellation } from '../../../interfaces/analytic';
import SubscriptionSummary from '../../../feature/Dashboard/Analytics/SubscriptionSummary';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import PendingOrganizationInvite from '../../../feature/TeamManagement/PendingOrganizationInvite';
import { analyticTabs } from '../../../constants';
import { routes } from '../../../constants/routes';
import AddPaymentCard from '../../../feature/AccountSubscription/Payments/AddPaymentCard';
import CancelledSubscriptions from '../../../feature/Websites/CancelledSubscriptions';
import SessionLimitReports from '../../../feature/Websites/SessionLimitReports';
import { useIntercom } from 'react-use-intercom';
import CancellatationSummary from '../../../feature/Dashboard/Analytics/CancellatationSummary';

export default function Dashboard() {
  const [tab, setTab] = useState<number>(1);
  const [analyticTab, setAnalyticTab] = useState<number>(1);
  const [pendingInviteOpen, setPendingInviteOpen] = useState<boolean>(false);
  const [addCardOpen, setAddCardOpen] = useState<boolean>(false);
  const [cancelledSubscriptionOpen, setCancelledSubscriptionOpen] = useState<boolean>(false);
  const [limitReportOpen, setLimitReportOpen] = useState<boolean>(false);

  const { loadAnalytics, loading: analyticsLoading, errorMessage: analyticsError } = useAnalytics();
  const { boot } = useIntercom();

  const { profile, loading } = useContext(OrganizationContext);

  const auth = useAuthUser();

  const [analyticsData, setAnalyticsData] = useState<IAnalytic | null>(null);

  const isGuestWithReadAccess = profile.profile_details?.permission == 'read';
  const isGuestWithWriteAccess = profile.profile_details?.permission == 'write';
  const isAccountHolder = profile.profile_details?.permission == 'admin';
  const isSuperUser = auth()?.super_user === 1;

  const ref = useRef(false);

  useEffect(() => {
    if (process.env.NODE_ENV == 'production' && !ref.current && typeof window !== 'undefined') {
      window.profitwell('start', {
        user_email: auth()?.email
      });
      ref.current = true;
    }
  }, []);

  useEffect(() => {
    boot();
    if (auth()?.super_user == 1) {
      loadAnalytics().then((res) => {
        setAnalyticsData({
          chart: res.msg.chart,
          onboarding: res.msg.onboarding,
          subscriptions: res.msg.subscriptions,
          today: res.msg.today,
          websites: res.msg.websites
        });
      });
    }
    setTimeout(() => {
      if (profile.organizations.filter((item) => item.status == 'pending').length > 0)
        setPendingInviteOpen(true);
    }, 5000);
  }, []);

  const subscriptionTabs = [
    {
      name: 'All Linked Subscriptions',
      id: 1,
      total: profile.subscription_details?.subscription
        ? profile.subscription_details?.subscription.filter((item) => item.idsite != 0).length
        : 0,
      paused: profile.subscription_details?.subscription
        ? profile.subscription_details?.subscription.filter(
            (item) => item.idsite != 0 && item.status == 'paused'
          ).length
        : 0,
      cancelled: profile.subscription_details?.subscription
        ? profile.subscription_details?.subscription.filter(
            (item) => item.idsite != 0 && item.status == 'cancelled'
          ).length
        : 0
    },
    {
      name: 'Subscriptions without Websites',
      id: 2,
      total:
        (auth()?.account_holder == 1 || auth()?.permission == 'write') &&
        profile.subscription_details?.subscription.filter((item) => item.idsite == 0).length
          ? profile.subscription_details?.subscription.filter((item) => item.idsite == 0).length
          : 0,
      paused:
        (auth()?.account_holder == 1 || auth()?.permission == 'write') &&
        profile.subscription_details?.subscription.filter(
          (item) => item.idsite == 0 && item.status == 'paused'
        ).length
          ? profile.subscription_details?.subscription.filter(
              (item) => item.idsite == 0 && item.status == 'paused'
            ).length
          : 0,
      cancelled:
        (auth()?.account_holder == 1 || auth()?.permission == 'write') &&
        profile.subscription_details?.subscription.filter(
          (item) => item.idsite == 0 && item.status == 'cancelled'
        ).length
          ? profile.subscription_details?.subscription.filter(
              (item) => item.idsite == 0 && item.status == 'cancelled'
            ).length
          : 0
    }
  ];

  const ExceededLimitReportCTA = profile.subscription_caps &&
    Object.keys(profile.subscription_caps)
      .map((key) => ({
        idSite: parseInt(key),
        session: profile.subscription_caps[key].session,
        revenue: profile.subscription_caps[key].revenue,
        teams: profile.subscription_caps[key].teams
      }))
      .filter((item) => item.session.limit_hit == 'yes').length > 0 && (
      <div>
        <button
          type={'button'}
          onClick={() => setLimitReportOpen(!limitReportOpen)}
          className={`w-full lg:w-auto rounded px-3 py-2 flex items-center justify-center lg:justify-start gap-2 border border-red-500 ${
            limitReportOpen ? 'bg-red-100' : ''
          } bg-red-50 hover:bg-red-100 text-red-500`}>
          <span>
            <i className="fi fi-rr-sensor-alert text-sm"></i>
          </span>
          <span className={'text-xs font-medium'}>View Exceeded Limits Report</span>
        </button>
      </div>
    );

  return (
    <>
      <Layout>
        <div>
          {pendingInviteOpen && (
            <PendingOrganizationInvite closeModal={() => setPendingInviteOpen(false)} />
          )}
          {addCardOpen && profile.subscription_details && (
            <AddPaymentCard
              customerId={profile.subscription_details.stripe_customer_id}
              closeModal={() => setAddCardOpen(false)}
            />
          )}
          {cancelledSubscriptionOpen && (
            <CancelledSubscriptions
              data={
                analyticsData?.subscriptions
                  .clients_pending_cancellation as IClientsPendingCancellation
              }
              closeModal={() => setCancelledSubscriptionOpen(false)}
            />
          )}
          {limitReportOpen && profile && (
            <SessionLimitReports
              data={
                profile.subscription_caps &&
                Object.keys(profile.subscription_caps)
                  .map((key) => ({
                    idSite: parseInt(key),
                    session: profile.subscription_caps[key].session,
                    revenue: profile.subscription_caps[key].revenue,
                    teams: profile.subscription_caps[key].teams
                  }))
                  .filter((item) => item.session.limit_hit == 'yes')
              }
              closeModal={() => setLimitReportOpen(false)}
            />
          )}
          <div>
            <div
              className={
                'p-4 md:p-5 bg-gradient-to-r from-emerald-100 to-gray-200 flex flex-col lg:flex-row gap-2 justify-between'
              }>
              <div>
                <h5 className={'font-medium text-base md:text-xl capitalize'}>
                  Welcome, {auth()?.full_name?.split(' ')[0]}
                </h5>
                {auth()?.super_user == 0 &&
                profile.subscription_details &&
                profile.subscription_details?.subscription.filter((item) => item.idsite == 0)
                  .length > 0 ? (
                  <p className={'mt-1'}>
                    You have{' '}
                    <strong>
                      {
                        profile.subscription_details?.subscription.filter(
                          (item) => item.idsite == 0
                        ).length
                      }
                    </strong>{' '}
                    unused subscriptions
                  </p>
                ) : null}
              </div>
              {profile.organizations.filter((item) => item.status == 'pending').length > 0 && (
                <div
                  onClick={() => setPendingInviteOpen(true)}
                  className={
                    'p-2 bg-white border-l-2 lg:border-l-4 border-emerald-600 rounded shadow flex justify-between gap-2 cursor-pointer'
                  }>
                  <div className={'flex gap-2'}>
                    <span className="relative flex h-3 w-3">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-3 w-3 bg-emerald-600"></span>
                    </span>
                    <div>
                      <h5 className={'text-sm font-medium text-emerald-600'}>Pending invitation</h5>
                      <p className={'text-xs md:text-sm'}>
                        You have{' '}
                        {profile.organizations.filter((item) => item.status == 'pending').length}{' '}
                        pending organization invitations
                      </p>
                    </div>
                  </div>
                  <div>
                    <span>
                      <i className="fi fi-rr-angle-right text-sm"></i>
                    </span>
                  </div>
                </div>
              )}
              {/*{profile &&*/}
              {/*  profile.subscription_details?.subscription &&*/}
              {/*  profile.subscription_details?.subscription.filter(*/}
              {/*    (item) => item.website?.grace == 1*/}
              {/*  ).length > 0 && (*/}
              {/*    <div*/}
              {/*      // onClick={() => setPendingInviteOpen(true)}*/}
              {/*      className={*/}
              {/*        'lg:max-w-[60vw] p-3 bg-amber-50 border-l-2 lg:border-l-4 border-amber-600 rounded shadow flex justify-between gap-2 cursor-pointer'*/}
              {/*      }>*/}
              {/*      <div className={'flex gap-2'}>*/}
              {/*        <div>*/}
              {/*          <span>*/}
              {/*            <i className="fi fi-ss-triangle-warning text-2xl text-amber-500"></i>*/}
              {/*          </span>*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <h5 className={'text-sm font-semibold text-amber-600'}>Upgrade Warning</h5>*/}
              {/*          <p className={'text-sm md:text-sm'}>*/}
              {/*            You have{' '}*/}
              {/*            <strong>*/}
              {/*              {profile.subscription_details?.subscription &&*/}
              {/*                profile.subscription_details?.subscription.filter(*/}
              {/*                  (item) => item.website?.grace == 1*/}
              {/*                ).length}*/}
              {/*            </strong>{' '}*/}
              {/*            websites that require subscription upgrade.*/}
              {/*          </p>*/}
              {/*          <p className={'text-xs md:text-sm mt-2'}>*/}
              {/*            Your Revenue is above your current package. Next month you’ll track the*/}
              {/*            revenue of your current package. Upgrade to avoid any data-loss.*/}
              {/*          </p>*/}
              {/*          <div className={'mt-2'}>*/}
              {/*            <button*/}
              {/*              title={'Upgrade Plan'}*/}
              {/*              type={'button'}*/}
              {/*              onClick={() => {*/}
              {/*                // setUpgradeOpen(true);*/}
              {/*              }}*/}
              {/*              className={*/}
              {/*                'rounded-full px-3 py-1 flex items-start gap-2 shadow hover:bg-amber-500 bg-amber-600 font-medium text-white text-sm'*/}
              {/*              }>*/}
              {/*              Upgrade Now*/}
              {/*            </button>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <span className="relative flex h-3 w-3">*/}
              {/*          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75"></span>*/}
              {/*          <span className="relative inline-flex rounded-full h-3 w-3 bg-amber-600"></span>*/}
              {/*        </span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  )}*/}
            </div>
            {isSuperUser && analyticsLoading ? (
              <div
                className={'flex h-[60vh] gap-3 flex-col justify-center items-center text-center'}>
                <LoadingSpinner color={'text-[#008160]'} />
                <p>Loading analytics</p>
              </div>
            ) : (
              isSuperUser && (
                <div className={'my-2'}>
                  {/*Overview tabs*/}
                  <div className={'flex justify-between gap-4 mb-5'}>
                    <div className={'flex gap-4'}>
                      {analyticTabs &&
                        analyticTabs.map((item, index) => (
                          <button
                            type={'button'}
                            onClick={() => setAnalyticTab(item.id)}
                            key={index}
                            className={`${
                              analyticTab === item.id
                                ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                                : 'border-transparent hover:border-gray-300'
                            } py-2 border-b-2 font-medium text-sm flex gap-3`}>
                            <span className={'text-sm'}>{item.name}</span>
                          </button>
                        ))}
                    </div>
                    <div className={'flex gap-3'}>
                      <button
                        type={'button'}
                        onClick={() => setCancelledSubscriptionOpen(!cancelledSubscriptionOpen)}
                        className={`rounded px-3 py-2 flex items-center gap-2 border border-[#008160] ${
                          cancelledSubscriptionOpen ? 'bg-emerald-100' : ''
                        } hover:bg-emerald-100 text-[#008160]`}>
                        <span>
                          <i className="fi fi-rr-ban text-xs"></i>
                        </span>
                        <span className={'text-xs'}>See all cancelled subscriptions</span>
                      </button>
                      {profile && ExceededLimitReportCTA}
                    </div>
                  </div>
                  {analyticTab == 1 ? (
                    <OnboardingSummary data={analyticsData as IAnalytic} />
                  ) : analyticTab == 2 ? (
                    <SubscriptionSummary data={analyticsData as IAnalytic} />
                  ) : analyticTab == 3 ? (
                    <CancellatationSummary data={analyticsData as IAnalytic} />
                  ) : null}
                </div>
              )
            )}
            {!isSuperUser && (
              <div className={'grid lg:grid-cols-6 gap-5 mt-5'}>
                <div className={'lg:col-span-6'}>
                  {profile.subscription_details?.subscription.length == 0 ? (
                    <div className={'flex justify-center items-center lg:h-[65vh]'}>
                      <div className={'lg:w-1/3'}>
                        <p className={'text-center'}>
                          Your account does not have any subscription at the moment.
                        </p>
                        <div className={'grid gap-5 mt-3'}>
                          <div
                            className={
                              'border-2 border-emerald-400 flex items-start gap-4 p-5 bg-white drop-shadow rounded-md'
                            }>
                            <div className={'rounded-full bg-emerald-50 p-3'}>
                              <img
                                src={'/assets/icons/credit-card.png'}
                                className={'w-[5vw]'}
                                alt={''}
                              />
                            </div>
                            <div className={'grid gap-2'}>
                              <h5 className={'text-gray-800 font-medium'}>Add a payment card</h5>
                              <p className={'text-sm'}>
                                Purchase your first subscription plan after adding your payment
                                card.
                              </p>
                              <div className={'mt-3'}>
                                <button
                                  type={'button'}
                                  onClick={() => setAddCardOpen(true)}
                                  className={
                                    'rounded px-2 md:px-3 py-1 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                                  }>
                                  <span>
                                    <i className="fi fi-rr-plus text-xs"></i>
                                  </span>
                                  <span className={'text-xs md:text-sm'}>Add card</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              'flex items-start gap-4 p-5 bg-white drop-shadow rounded-md'
                            }>
                            <div className={'rounded-full bg-emerald-50 p-3'}>
                              <img src={'/assets/icons/web.png'} className={'w-[5vw]'} alt={''} />
                            </div>
                            <div className={'grid gap-2'}>
                              <h5 className={'text-gray-800 font-medium'}>
                                Add your first website
                              </h5>
                              <p className={'text-sm'}>
                                Onboard your first website and verify to see the heatmaps
                              </p>
                              <div className={'mt-3'}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className={'flex justify-between gap-5 flex-col-reverse lg:flex-row'}>
                        <div className={'flex gap-4 mb-5'}>
                          {subscriptionTabs &&
                            subscriptionTabs.map((item, index) => (
                              <button
                                type={'button'}
                                onClick={() => setTab(item.id)}
                                key={index}
                                className={`${
                                  tab === item.id
                                    ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                                    : 'border-transparent'
                                } py-2 border-b-2 hover:border-gray-300 font-medium text-sm flex gap-3`}>
                                <span className={'text-xs md:text-sm'}>{item.name}</span>
                                <span
                                  className={
                                    'bg-emerald-100 text-emerald-500 font-medium px-2 rounded'
                                  }>
                                  {item.total}
                                </span>
                              </button>
                            ))}
                        </div>
                        {profile && ExceededLimitReportCTA}
                      </div>

                      <div>
                        {loading ? (
                          <div
                            className={'h-[60vh] flex flex-col justify-center items-center mb-5'}>
                            <LoadingSpinner color={'text-[#008160]'} />
                            <p>Loading subscriptions...</p>
                          </div>
                        ) : profile &&
                          profile.subscription_details &&
                          profile.subscription_details.subscription.length > 0 ? (
                          <div>
                            {tab === 1 &&
                            profile &&
                            profile.subscription_details?.subscription.filter((web) => web.website)
                              .length > 0 ? (
                              <div className={'grid gap-2'}>
                                {profile.subscription_details?.subscription
                                  ?.filter((web) => web.website)
                                  .map((item: any, index: number) => (
                                    <ActiveSubscriptionListCard key={index} item={item} />
                                  ))}
                              </div>
                            ) : tab === 1 &&
                              profile &&
                              profile.subscription_details?.subscription.filter(
                                (web) => web.website
                              ).length === 0 ? (
                              <div
                                className={
                                  'w-full p-5 h-[50vh] flex flex-col justify-center items-center'
                                }>
                                <img
                                  src={'/assets/icons/web.png'}
                                  width={128}
                                  height={128}
                                  className={'w-1/2 lg:w-[5vw]'}
                                  alt={''}
                                />
                                <h4 className={'font-normal mt-5 text-center'}>
                                  You currently do not have any website added.
                                </h4>
                                <div className={'mt-3 flex'}>
                                  <Link
                                    to={
                                      profile.subscription_details &&
                                      profile.subscription_details?.subscription.filter(
                                        (item) => !item.website
                                      ).length > 0
                                        ? routes.SETUP
                                        : routes.PRICING
                                    }
                                    className={
                                      'rounded px-4 py-2 flex items-start gap-2 bg-[#008160] text-white font-medium'
                                    }>
                                    <span>
                                      <i className="fi fi-rr-plus text-sm"></i>
                                    </span>
                                    <span className={'text-sm'}>Add a website</span>
                                  </Link>
                                </div>
                              </div>
                            ) : null}
                            {tab === 2 &&
                            profile.subscription_details &&
                            (isAccountHolder || isGuestWithWriteAccess) &&
                            profile.subscription_details.subscription.filter(
                              (item) => item.idsite === 0
                            ).length > 0 ? (
                              <PendingUnusedSubscription
                                userSubscription={profile.subscription_details as ISubscription}
                              />
                            ) : tab === 2 &&
                              profile.subscription_details &&
                              (isAccountHolder || isGuestWithWriteAccess) &&
                              profile.subscription_details.subscription.filter(
                                (item) => item.idsite === 0
                              ).length === 0 ? (
                              <div>
                                <p>No pending subscriptions</p>
                              </div>
                            ) : null}
                            {tab === 2 && profile.subscription_details && isGuestWithReadAccess && (
                              <div
                                className={
                                  'h-[60vh] p-10 flex gap-2 flex-col justify-center items-center text-center'
                                }>
                                <p>You do not have access to pending subscriptions.</p>
                                <p className={'text-sm text-amber-600'}>
                                  Contact your account owner to give you read and write access to
                                  manage pending unused subscriptions
                                </p>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}
