import { IButton } from '../../../../interfaces/components';

export default function PlanButton({ title, handler, disabled = false }: IButton) {
  return (
    <button
      type={'submit'}
      onClick={handler}
      disabled={disabled}
      style={{ fontFamily: "'CircularStd-Medium', sans-serif" }}
      className={`${
        disabled ? 'bg-white' : ''
      } px-3 py-2 text-center border border-[#091226] rounded w-full text-[#091226] bg-white font-black text-[0.875]`}>
      {title}
    </button>
  );
}
