import { IClient } from '../../interfaces/client';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useClients } from '../../pages/main/Clients/useClients';
import { useState } from 'react';
import Modal from '../../components/global/ui/Modal';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';

export default function ChangeClientStatus({
  client,
  closeModal
}: {
  client: IClient;
  closeModal: () => void;
}) {
  const { changeClientStatus, loading, errorMessage } = useClients();
  const [message, setMessage] = useState<string | null>(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Enter the account name')
  });

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema,
    onSubmit: () => handleChangeClientStatus()
  });

  const handleChangeClientStatus = async () => {
    setMessage(null);
    const response = await changeClientStatus({
      state: client.status != 'active',
      userId: client.user_id
    });
    if (response?.result == 'success') {
      setMessage(response?.msg);
      setTimeout(() => {
        closeModal();
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      title={client.status == 'active' ? 'Confirm Deactivation' : 'Confirm Activation'}
      width={'lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>{`${
            client.status == 'active' ? 'Deactivating' : 'Activating'
          } account...Please wait`}</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div>
                <p className={'text-sm'}>
                  {`Kindly confirm the ${
                    client.status == 'active' ? 'deactivation' : 'activation'
                  } of ${client.full_name}.`}
                </p>
              </div>
              <div className={'mt-5'}>
                <p className={'text-sm'}>
                  Type <strong>{client.full_name}</strong> below to proceed
                </p>
                <div className={'my-1'}>
                  <input
                    type={'text'}
                    name={'name'}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className="w-full bg-transparent outline-0 px-3 py-2 rounded border border-gray-300 mt-1 flex items-center"
                  />

                  <p className={'text-xs text-red-500'}>
                    {formik.touched.name && formik.errors.name}
                  </p>
                </div>
              </div>
              <div className={'grid gap-2'}>
                <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-end gap-2'}>
                  <button
                    type={'button'}
                    onClick={closeModal}
                    className={`px-4 py-2 rounded text-sm text-gray-700 bg-gray-200 hover:bg-gray-300`}>
                    Cancel
                  </button>
                  <button
                    type={'button'}
                    onClick={() => formik.handleSubmit()}
                    disabled={formik.values.name != client.full_name}
                    className={`rounded bg-white font-medium px-4 py-2 text-xs  ${
                      formik.values.name == '' || formik.values.name != client.full_name
                        ? 'border border-[#008160] text-[#008160] hover:bg-transparent'
                        : 'bg-[#008160] hover:bg-emerald-600 text-white'
                    }`}>
                    {`Yes, proceed`}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
