export default function SuccessMessage({ message }: { message: string }) {
  return (
    <div className={'flex flex-col gap-3 justify-center text-center text-black'}>
      <div className={'flex justify-center items-center'}>
        <span
          className={
            'rounded-full bg-gradient-to-r from-[#008160] to-emerald-300 pt-2 flex justify-center items-center w-11 h-11'
          }>
          <i className="fi fi-rr-check text-lg text-white"></i>
        </span>
      </div>
      <p className={'text-sm font-medium'}>{message}</p>
    </div>
  );
}
