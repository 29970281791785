import { useContext, useRef } from 'react';
import useClickOutside from '../../../../hooks/useClickOutside';
import { IOrganization } from '../../../../interfaces/organization';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { useLocation } from 'react-router-dom';
import { routes } from '../../../../constants/routes';

export default function UserOrganizationsPopover({
  onClickOutside,
  show,
  hideOrganizations,
  setSelectedOrganization,
  options
}: {
  onClickOutside?: any;
  hideOrganizations: () => void;
  show: boolean;
  setSelectedOrganization: (item: IOrganization) => void;
  options: IOrganization[];
}) {
  if (!show) return null;

  const { pathname } = useLocation();

  const ref: any = useRef(null);
  useClickOutside(ref, onClickOutside);

  const { switchOrganization, selectedOrganization, fetchOrganizationData } =
    useContext(OrganizationContext);

  return (
    <div
      ref={ref}
      className={'absolute top-0 left-0 bg-white w-[20vw] drop-shadow-lg rounded z-50'}>
      <p className={'text-sm px-3 pt-3 pb-2'}>Switch organization</p>
      <hr className={'m-1'} />
      <div className={'slim-scroll max-h-[30vh] overflow-auto'}>
        {!(pathname == routes.ACCOUNT_TEAM || pathname == routes.SUPPORT) && (
          <div
            onClick={() => {
              // setSelectedOrganization(item);
              switchOrganization('all');
              hideOrganizations();
            }}
            className={`px-4 py-3 flex items-start gap-3 cursor-pointer hover:bg-emerald-50 border-gray-100 truncate border-l-2 ${
              !selectedOrganization ? 'bg-emerald-50 border-emerald-600' : 'border-transparent'
            }`}>
            <div className={`truncate flex flex-col w-full`}>
              <h6 className={'text-sm font-medium'}>All Accounts</h6>
            </div>
          </div>
        )}
        {options &&
          options.map((item, index) => (
            <div
              onClick={() => {
                setSelectedOrganization(item);
                switchOrganization(item.account_id as string);
                hideOrganizations();
              }}
              key={index}
              className={`px-4 py-3 flex items-start gap-3 cursor-pointer hover:bg-emerald-50 border-gray-100 truncate border-l-2 ${
                selectedOrganization && item.account_id == selectedOrganization.account_id
                  ? 'bg-emerald-50 border-emerald-600'
                  : 'border-transparent'
              }`}>
              <div className={`truncate flex flex-col w-full`}>
                <h6 className={'text-sm font-medium'}>{item.name}</h6>
                <div className={'flex justify-between items-center'}>
                  <span className={'text-xs rounded-full bg-emerald-100 px-2 py-1'}>
                    {item.access_label}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
