import { useRef } from 'react';
import useClickOutside from '../../../../hooks/useClickOutside';
import { IPaymentCard } from '../../../../interfaces/subscription';

export default function SubscriberCardPopover({
  onClickOutside,
  show,
  hideCardOptions,
  setSelectedCard,
  options
}: {
  onClickOutside?: any;
  hideCardOptions: () => void;
  show: boolean;
  setSelectedCard: (item: IPaymentCard) => void;
  options: IPaymentCard[];
}) {
  if (!show) return null;

  const ref: any = useRef(null);
  useClickOutside(ref, onClickOutside);

  return (
    <div ref={ref} className={'absolute bottom-0 left-0 bg-white w-full drop-shadow-lg'}>
      <p className={'font-medium text-sm px-3 pt-3 pb-2'}>Select payment card</p>
      <hr className={'m-1'} />
      <div className={'max-h-[50vh] slim-scroll'}>
        {options &&
          options.map((item, index) => (
            <div
              onClick={() => {
                setSelectedCard(item);
                hideCardOptions();
              }}
              key={index}
              className={
                'px-4 py-3 flex items-start gap-3 cursor-pointer hover:bg-emerald-50 border-b border-gray-100'
              }>
              <img
                src={`/assets/payments/${
                  item.card.brand === 'Visa'
                    ? 'visa.svg'
                    : item.card.brand === 'MasterCard'
                    ? 'mastercard.svg'
                    : item.card.brand === 'Discover'
                    ? 'discover.svg'
                    : item.card.brand === 'American Express'
                    ? 'amex.svg'
                    : item.card.brand === 'Diners Club'
                    ? 'diners.svg'
                    : item.card.brand === 'UnionPay'
                    ? 'unionpay.svg'
                    : item.card.brand === 'JCB'
                    ? 'jcb.svg'
                    : ''
                }`}
                className={'w-1/6'}
              />
              <div className={'w-5/6'}>
                <h6 className={'text-sm font-medium'}>**** **** **** {item.card.last4}</h6>
                <span className={'flex justify-between gap-3'}>
                  <span className={'text-xs'}>{item.card.brand}</span>
                  {item.is_default_source == 1 && (
                    <span className={'italic text-xs text-gray-500'}>Default</span>
                  )}
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
