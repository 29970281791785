import { useContext, useState } from 'react';
import { getRequest } from '../../../requests';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useAuthHeader } from 'react-auth-kit';
import { ORGANIZATION_ID } from '../../../constants';
import { useUserSites } from '../../../hooks/useUserSites';
import { OrganizationContext } from '../../../contexts/OrganizationContext';

export const useSignup = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');
  // const signIn = useSignIn();

  const { loadUserProfile } = useUserSites();
  // const { verifyEmailAccount } = useVerifyEmail();
  // const navigate = useNavigate();

  const { setProfile, setSelectedOrganization } = useContext(OrganizationContext);

  const signUp = async ({
    name,
    username,
    email,
    password,
    invite,
    waiting
  }: {
    name: string;
    username: string;
    email: string;
    password: string;
    invite: string | null;
    waiting?: number;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      setLoading(true);

      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const gReCaptchaToken = await executeRecaptcha('register');

      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.register',
          username: username,
          fullName: name,
          invite,
          format: 'json',
          password,
          email,
          waiting,
          reCaptchaToken: gReCaptchaToken
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
        } else if (response.data.result === 'success') {
          localStorage.setItem('email', email);
          localStorage.setItem('username', username);
        }
        setLoading(false);
        return response.data;
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error creating an account');
    }
  };
  const accountHolderRequest = async ({
    user_id,
    resend
  }: {
    user_id: number | null;
    resend?: number | null;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      setLoading(true);

      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.accountHolderRequest',
          user_id,
          token,
          resend,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
        } else if (response.data.result === 'success') {
          // verify the account request after successful request
          const verificationResponse = await validateAccountHolderRequest({
            email: response.data.email,
            token: response.data.token
          });

          if (verificationResponse) {
            setLoading(false);
            return verificationResponse;
          }
        }
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error requesting account holder access');
    }
  };

  const validateAccountHolderRequest = async ({
    email,
    token
  }: {
    email: string;
    token: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      setLoading(true);

      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.validateAccountHolderRequest',
          email,
          token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
        } else if (response.data.result === 'success') {
          // load profile of the new account
          await loadUserProfile({}).then((profileRes) => {
            setProfile(profileRes);
            setSelectedOrganization(profileRes.organizations[0]);
            localStorage.setItem(ORGANIZATION_ID, profileRes.account_id);
          });

          setLoading(false);
          return response.data;
        }
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error validating account holder');
    }
  };

  const addToCartEvent = async ({ plan, token }: { plan: string; token: string }) => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.addToCartEvent',
          token,
          plan,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error submitting add to cart event');
      setLoading(false);
    }
  };

  return {
    signUp,
    accountHolderRequest,
    validateAccountHolderRequest,
    addToCartEvent,
    errorMessage,
    loading
  };
};
