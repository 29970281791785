import React, { useState } from 'react';
import { useQuery } from '../../../util/global';
import OnboardingProgressBar from './OnboardingProgressBar';
import LoadingSpinnerMini from '../../../components/global/LoadingSpinnerMini';
import { useEditWebsite } from '../../../pages/main/Websites/useEditWebsite';
import Error from '../../../components/global/ui/alerts/Error';
import { brands } from '../../../constants';

export interface IBrand {
  id: number;
  name: string;
  key: string;
  icon: string;
}

export default function PlatformBrand({
  changeStep,
  setTracking,
  selectedPageBuilderStatus,
  setSelectedPageBuilderStatus
}: {
  changeStep: (s: number) => void;
  selectedPageBuilderStatus: string | null;
  setSelectedPageBuilderStatus: any;
  setTracking: any;
}) {
  const [selectedBrand, setSelectedBrand] = useState<IBrand | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const { editWebsite, loading, errorMessage } = useEditWebsite();
  const query = useQuery();

  const [brandStep, setBrandStep] = useState<number>(1);

  const handleSelectWebsitePlatform = async () => {
    setMessage(null);
    const response = await editWebsite({
      siteId: query && (query.get('siteId') as string),
      platform: selectedBrand?.key.toLowerCase(),
      url: query && (query.get('url') as string),
      tracking: 1
    });

    if (response?.result === 'success') {
      setMessage(response.msg);
      setTracking({
        script: response.tracking.script,
        orderStatusScript: response.tracking.orderstatus,
        postPurchaseScript: response.tracking.postpurchase,
        woocommerce: response.tracking.woocommerce,
        bigcommerce: response.tracking.bigcommerce,
        conversion: response.tracking.conversion,
        platform: response.website.platform
      });
      setBrandStep(2);
      // changeStep(4);
    }
  };

  const handleConfirmPageBuilderStatus = async () => {
    setMessage(null);
    const response = await editWebsite({
      siteId: query && (query.get('siteId') as string),
      page_builder: selectedPageBuilderStatus?.toLowerCase(),
      url: query && (query.get('url') as string),
      tracking: 1
    });

    if (response?.result === 'success') {
      setMessage(response.msg);
      changeStep(4);
    }
  };

  return (
    <>
      <div className="lg:w-[70%] w-full">
        <OnboardingProgressBar step={3} />

        {brandStep == 1 && (
          <div className="mb-3">
            <p className="text-3 black my-8 text-align-center">Where is this site hosted?</p>

            <div>
              <div className={'grid grid-cols-2 md:grid-cols-3 gap-4'}>
                {brands &&
                  brands.map((item) => (
                    <button
                      key={item.id}
                      onClick={() => setSelectedBrand(item)}
                      className={`border rounded-lg shadow p-5 ${
                        selectedBrand?.id == item.id
                          ? 'bg-[#008160] hover:bg-[#008160] text-white hover:text-white'
                          : 'hover:bg-[#E2FDEC]'
                      } cursor-pointer`}>
                      <div className={'flex flex-col justify-center items-center gap-2'}>
                        <img src={item.icon} width={40} alt={''} className={'w-[25%]'} />

                        <div>
                          <p className={'font-semibold text-xs text-center'}>{item.name}</p>
                          {item.id == 2 && <p className={'text-xs italic'}>(Coming soon)</p>}
                        </div>
                      </div>
                    </button>
                  ))}
              </div>
              <div className={'flex justify-center mt-5'}>
                <div className={'w-full md:w-[60%] grid gap-3'}>
                  <button
                    disabled={!selectedBrand || selectedBrand.id == 2}
                    type={'button'}
                    onClick={handleSelectWebsitePlatform}
                    className={`rounded-md px-3 py-3 ${
                      selectedBrand && !(selectedBrand?.id == 2)
                        ? 'bg-[#008160] hover:bg-emerald-600 text-white'
                        : 'bg-[#F6F6F6] text-gray-600'
                    } w-full font-medium text-sm`}>
                    {loading ? (
                      <div className={'flex justify-center'}>
                        <LoadingSpinnerMini color={'text-white'} />
                      </div>
                    ) : (
                      'Next'
                    )}
                  </button>
                  <button
                    type={'button'}
                    onClick={() => changeStep(2)}
                    className={
                      'px-3 py-2 bg-transparent text-[#008160] w-full font-medium text-sm'
                    }>
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {brandStep == 2 && (
          <div className="mb-3">
            <p className="text-3 black my-8 text-align-center">
              Do you use any additional 3rd party page builders?
            </p>

            <div>
              <p className={'text-center'}>
                <span className={'font-medium'}>Examples:</span> <br />
                Unbounce, Replo, Zipify, Gempages, Shogun, Webflow, etc
              </p>
            </div>
            <div className={'flex justify-center mt-10'}>
              <div className={'grid grid-cols-2 gap-5 flex-1 w-2/3'}>
                {['Yes', 'No'].map((item, index) => (
                  <button
                    type={'button'}
                    key={index}
                    onClick={() => setSelectedPageBuilderStatus(item)}
                    className={`bg-white drop-shadow font-medium px-5 py-2 text-base border border-gray-300 rounded ${
                      selectedPageBuilderStatus == item
                        ? 'bg-[#008160] text-white hover:text-white hover:bg-[#008160]'
                        : 'hover:bg-[#E2FDEC]'
                    } cursor-pointer`}>
                    {item}
                  </button>
                ))}
              </div>
            </div>
            <div className={'flex justify-center mt-5'}>
              <div className={'w-full md:w-[60%] grid gap-3'}>
                <button
                  disabled={!selectedPageBuilderStatus}
                  type={'button'}
                  onClick={handleConfirmPageBuilderStatus}
                  className={`rounded-md px-3 py-3 ${
                    selectedPageBuilderStatus
                      ? 'bg-[#008160] hover:bg-emerald-600 text-white'
                      : 'bg-[#F6F6F6] text-gray-600'
                  } w-full font-medium text-sm`}>
                  {loading ? (
                    <div className={'flex justify-center'}>
                      <LoadingSpinnerMini color={'text-white'} />
                    </div>
                  ) : (
                    'Next'
                  )}
                </button>
                <button
                  type={'button'}
                  onClick={() => changeStep(2)}
                  className={'px-3 py-2 bg-transparent text-[#008160] w-full font-medium text-sm'}>
                  Go Back
                </button>
              </div>
            </div>
          </div>
        )}

        <div className={'flex flex-col justify-center items-center'}>
          {errorMessage && <Error value={errorMessage as string} />}
        </div>
      </div>
    </>
  );
}
