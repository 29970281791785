import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { IPlan, IPlanInterval } from '../../../interfaces/components';
import { usePayment } from '../../../pages/main/Pricing/usePayment';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Error from '../../../components/global/ui/alerts/Error';
import { useAuthUser } from 'react-auth-kit';
import ConfirmSubscription from './ConfirmSubscription';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';
import { IPaymentCard, ISubscription } from '../../../interfaces/subscription';
import { ORGANIZATION_ID, SUBSCRIPTION_ID_KEY } from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';
import SubscriberCardPopover from '../../../components/global/ui/popovers/SubscriberCardPopover';

export default function SubscriptionCheckoutForm({
  plan,
  interval,
  subscription
}: {
  plan: IPlan;
  interval: IPlanInterval;
  subscription: ISubscription;
}) {
  const { makePayment, confirmPayment, addCardToSubscription, loading, errorMessage } =
    usePayment();
  const {
    profile,
    selectedOrganization,
    fetchOrganizationData,
    loading: subscriptionLoading
  } = useContext(OrganizationContext);

  const [error, setError] = useState<string | null | undefined>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<IPaymentCard | null | undefined>(
    profile && profile.subscription_details?.card.find((item) => item.is_default_source == 1)
  );
  const [cardOptionsShow, setCardOptionsShow] = useState<boolean>(false);

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    plan: Yup.string().required('Select your plan'),
    email:
      subscription && subscription.card?.length > 0
        ? Yup.string().email('Enter a valid email')
        : Yup.string().email('Enter a valid email').required('Enter your email'),
    name:
      subscription.card?.length > 0
        ? Yup.string()
        : Yup.string().required('Enter the name on card'),
    interval: Yup.string().required('Specify the interval'),
    coupon: Yup.string()
  });

  const auth = useAuthUser();

  const formik = useFormik({
    initialValues: {
      plan: plan && plan.value,
      name: '',
      coupon: '',
      interval: interval && interval.value,
      email: auth() && auth()?.email,
      card_id:
        subscription.card && subscription.card.length > 0
          ? subscription.card.filter((item) => item.is_default_source)[0].id
          : ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setMessage(null);
      setError(null);

      const response = await makePayment({
        name: values.name,
        email: values.email,
        plan: plan && plan.value,
        card_id: selectedCard
          ? selectedCard.id
          : subscription.card.find((item) => item.is_default_source)?.id,
        interval: values.interval,
        coupon: values.coupon,
        account_id: selectedOrganization && selectedOrganization.account_id
      });
      if (response.msg) {
        // Destructure subscriptionId to confirm payment of Subscription
        const { subscriptionId } = response.msg;

        // Use existing card if user already has at leas one attached
        if (values.card_id) {
          // Proceed with confirming payment and charge the selected card
          const confirmation = await confirmPayment(subscriptionId, 'no');
          if (confirmation.result === 'success') {
            setMessage(confirmation.msg);
            // save subscription ID to local storage to be used on the setup page
            localStorage.setItem(SUBSCRIPTION_ID_KEY, subscriptionId);

            setTimeout(() => {
              // refetch organization data
              fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
              navigate(routes.SETUP);
            }, 2000);
          }
        } else {
          const confirmation = await confirmPayment(subscriptionId, 'yes');

          // Add payment to card to Subscription after confirming payment
          if (confirmation.msg) {
            const addCard = await addCardToSubscription(subscriptionId);
            if (addCard.result === 'success') {
              setMessage(confirmation.msg);

              // save subscription ID to local storage to be used on the setup page
              localStorage.setItem(SUBSCRIPTION_ID_KEY, subscriptionId);

              setTimeout(() => {
                // refetch organization data
                fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
                navigate(routes.SETUP);
              }, 1000);
            }
          }
        }
      } else {
        setError('Error completing subscription');
      }
    }
  });

  return (
    <>
      {confirmOpen && (
        <ConfirmSubscription
          formik={formik}
          plan={plan}
          interval={interval}
          closeModal={() => setConfirmOpen(false)}
        />
      )}
      <div className={'rounded-md border border-gray-200 p-3 md:p-5 '}>
        <div className={'mb-2'}>
          <h6 className={'font-semibold md:font-medium text-sm lg:text-base'}>Checkout</h6>
        </div>
        {message ? (
          <div className={'h-[25vh] flex flex-col justify-center items-center text-center'}>
            {/*<h4 className={'text-[#008160] text-lg'}>{message}</h4>*/}
            <SuccessMessage message={message} />
            <p className={'text-sm text-gray-400'}>Redirecting to add a website...</p>
          </div>
        ) : (
          <div className={'flex flex-col gap-4 '}>
            <div>
              <label className={'capitalize text-sm'}>
                Email <span className={'text-red-500'}>*</span>{' '}
              </label>
              <input
                type={'email'}
                className={'p-2 w-full rounded border border-gray-300 mt-1'}
                name={'email'}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {/*<p className={'text-sm text-red-500'}>{formik.touched.email && formik.errors.email}</p>*/}
            </div>
            <div className={'grid md:grid-cols-2 gap-3'}>
              <div>
                <label className={'capitalize text-sm'}>
                  Select payment card <span className={'text-red-500'}>*</span>{' '}
                </label>
                <div className={'relative w-full mt-1'}>
                  <button
                    type={'button'}
                    onClick={() => setCardOptionsShow(!cardOptionsShow)}
                    className={`border px-2 py-2 flex justify-between ${
                      cardOptionsShow ? 'border-gray-300' : 'border-gray-300'
                    } w-full rounded text-sm`}>
                    {selectedCard ? (
                      <div className={'flex items-start'}>
                        <div className={'flex justify-start items-start gap-3'}>
                          <img
                            src={`/assets/payments/${
                              selectedCard.card.brand === 'Visa'
                                ? 'visa.svg'
                                : selectedCard.card.brand === 'MasterCard'
                                ? 'mastercard.svg'
                                : selectedCard.card.brand === 'Discover'
                                ? 'discover.svg'
                                : selectedCard.card.brand === 'American Express'
                                ? 'amex.svg'
                                : selectedCard.card.brand === 'Diners Club'
                                ? 'diners.svg'
                                : selectedCard.card.brand === 'UnionPay'
                                ? 'unionpay.svg'
                                : selectedCard.card.brand === 'JCB'
                                ? 'jcb.svg'
                                : ''
                            }`}
                            alt={''}
                            className={'w-[12%]'}
                          />
                          <div className={'text-left flex gap-2'}>
                            <span className={'text-xs'}>{selectedCard.card.brand}</span>
                            <span>**** **** **** {selectedCard.card.last4}</span>
                          </div>
                        </div>
                        <span>
                          <i
                            className={`fi ${
                              cardOptionsShow ? 'fi-sr-caret-up' : 'fi-sr-caret-down'
                            } text-lg`}></i>
                        </span>
                      </div>
                    ) : (
                      <div className={'flex items-start'}>
                        <div className={'flex justify-start items-start gap-3'}>
                          <img
                            src={`/assets/payments/${
                              profile.subscription_details?.card[0].card.brand === 'Visa'
                                ? 'visa.svg'
                                : profile.subscription_details?.card[0].card.brand === 'MasterCard'
                                ? 'mastercard.svg'
                                : profile.subscription_details?.card[0].card.brand === 'Discover'
                                ? 'discover.svg'
                                : profile.subscription_details?.card[0].card.brand ===
                                  'American Express'
                                ? 'amex.svg'
                                : profile.subscription_details?.card[0].card.brand === 'Diners Club'
                                ? 'diners.svg'
                                : profile.subscription_details?.card[0].card.brand === 'UnionPay'
                                ? 'unionpay.svg'
                                : profile.subscription_details?.card[0].card.brand === 'JCB'
                                ? 'jcb.svg'
                                : ''
                            }`}
                            className={'w-[10%] xl:w-[9%]'}
                          />
                          <div className={'text-left flex gap-2'}>
                            <span className={'text-xs'}>
                              {profile.subscription_details?.card[0].card.brand}
                            </span>
                            <span>
                              **** **** **** {profile.subscription_details?.card[0].card.last4}
                            </span>
                          </div>
                        </div>
                        <span>
                          <i
                            className={`fi ${
                              cardOptionsShow ? 'fi-sr-caret-up' : 'fi-sr-caret-down'
                            } text-base`}></i>
                        </span>
                      </div>
                    )}
                  </button>
                  {cardOptionsShow && (
                    <SubscriberCardPopover
                      hideCardOptions={() => setCardOptionsShow(false)}
                      show={cardOptionsShow}
                      setSelectedCard={setSelectedCard}
                      options={profile.subscription_details?.card as any[]}
                      onClickOutside={() => setCardOptionsShow(false)}
                    />
                  )}
                </div>
              </div>
              <div>
                <label className={'capitalize text-sm'}>Coupon</label>
                <input
                  type={'text'}
                  className={'p-2 w-full rounded border border-gray-300 mt-1'}
                  name={'coupon'}
                  value={formik.values.coupon}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className={'flex gap-3 flex-col-reverse md:flex-row justify-between'}>
              <Link
                to={routes.DASHBOARD}
                className={`px-4 py-2 flex justify-center items-center rounded text-sm text-gray-700 bg-gray-200 hover:bg-gray-300`}>
                <span>Cancel</span>
              </Link>
              <div className={'flex lg:w-fit'}>
                <PrimaryButton
                  disabled={loading}
                  title={loading ? 'Creating Subscription...' : 'Proceed to subscribe'}
                  handler={() => setConfirmOpen(true)}
                />
              </div>
            </div>
            {errorMessage && <Error value={errorMessage} />}
            {error && <Error value={error} />}
          </div>
        )}
      </div>
    </>
  );
}
