export default function Modal({
  children,
  width,
  title,
  closeModal
}: {
  children: any;
  width: string;
  title: string;
  closeModal: () => void;
}) {
  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div
          className={`relative inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:my-0 sm:align-middle ${width}`}>
          <div className={'bg-white p-3'}>
            <div className={'flex justify-between'}>
              <h5 className={'font-medium text-sm capitalize'}>{title}</h5>
              <button type={'button'} onClick={closeModal}>
                <i className="fi fi-ss-cross text-xs"></i>
              </button>
            </div>
            <hr className={'border-b border-gray-100 my-2'} />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
