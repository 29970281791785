import Modal from '../../../components/global/ui/Modal';
import { IPlan, IPlanInterval } from '../../../interfaces/components';

export default function ConfirmSubscription({
  plan,
  interval,
  formik,
  closeModal
}: {
  plan: IPlan;
  interval: IPlanInterval;
  formik: any;
  closeModal: () => void;
}) {
  return (
    <Modal closeModal={closeModal} title={'Confirm Subscription'} width={'lg:w-1/3'}>
      <div>
        <div>
          <p className={'text-sm'}>
            {`Kindly confirm your purchase of ${plan.name} plan ${interval.name}.`}
          </p>
          <p className={'text-sm mt-2'}>
            Your subscription will be attached to this payment method.
          </p>
          <div className={'my-2 bg-gray-100 rounded p-5'}>
            <h5 className={'font-medium text-sm mb-2'}>Summary</h5>
            <div className={'flex justify-between gap-2 text-sm'}>
              <span>Package</span>
              <span className={'font-medium'}>{plan.name}</span>
            </div>
            <div className={'flex justify-between gap-2 text-sm'}>
              <span>Period</span>
              <span className={'font-medium'}>{interval.name}</span>
            </div>
            <hr className={'my-3'} />
            <div className={'flex justify-between text-sm'}>
              <span>Total Amount: </span>
              <span className={'text-lg font-semibold'}>
                {interval.value === 'month'
                  ? `$${plan.monthlyPrice}/month`
                  : `$${(plan.yearlyPrice as number) * 12}/year`}
              </span>
            </div>
          </div>
        </div>
        <div className={'grid gap-2'}>
          <div className={'mt-2 flex flex-col-reverse md:flex-row justify-end gap-2'}>
            <button
              type={'button'}
              onClick={closeModal}
              className={`px-4 py-2 rounded text-sm text-gray-700 bg-gray-200 hover:bg-gray-300`}>
              Cancel
            </button>
            <button
              type={'button'}
              onClick={() => {
                formik.handleSubmit();
                closeModal();
              }}
              className={`rounded hover:bg-emerald-600 font-medium px-4 py-2 text-sm bg-[#008160] text-white`}>
              {'Yes, proceed'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
