import { useAppSettings } from '../../pages/main/PlatformSettings/useAppSettings';
import React, { useEffect, useState } from 'react';
import { ISettingsTab } from '../../interfaces/global';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import DynamicField from './WebsiteSettings';
import { FormikValues, useFormik } from 'formik';
import SuccessMessage from '../../components/global/ui/SuccessMessage';

const WebsiteSettingsOptions: React.FC<{ tabs: ISettingsTab[]; siteId: number }> = ({
  tabs,
  siteId
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { updateWebsiteSettings } = useAppSettings();
  const [message, setMessage] = useState<string | null>(null);

  const initialValues: FormikValues = {};
  tabs.forEach((tab) => {
    tab.settings.forEach((setting) => {
      initialValues[setting.name] = setting.value || '';
    });
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => handleUpdateWebsiteSettings(values)
  });

  console.log('t', tabs);

  const handleUpdateWebsiteSettings = async (values: FormikValues) => {
    setMessage(null);
    setLoading(true);
    console.log('va', values);
    const response = await updateWebsiteSettings({
      siteId,
      settings: JSON.stringify(values)
    });

    if (response?.result === 'success') {
      setLoading(false);
      setMessage(response.msg);
      // refetch organization data
      // fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  };

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div>
      <div className={'flex gap-4'}>
        {tabs &&
          tabs.map((tab, index) => (
            <button
              type={'button'}
              onClick={() => handleTabClick(index)}
              key={index}
              className={`${
                activeTab === index
                  ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                  : 'border-transparent hover:border-gray-300'
              } py-2 border-b-2 font-medium text-sm flex gap-3`}>
              <span className={'text-sm'}>{tab.title}</span>
            </button>
          ))}
      </div>
      {loading ? (
        <div className={'lg:h-[50vh] flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Updating website settings...Please wait</h5>
        </div>
      ) : message ? (
        <div className={'lg:h-[50vh] flex flex-col justify-center items-center p-5'}>
          <SuccessMessage message={message} />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-4 flex flex-col gap-3 lg:h-[50vh] slim-scroll pr-3 overflow-y-auto">
            {tabs &&
              tabs[activeTab].settings.map((setting, index) => (
                <DynamicField key={index} fieldData={setting as any} formik={formik} />
              ))}
          </div>
          <div className={'mt-3 flex justify-end'}>
            <button
              type="submit"
              className={`rounded px-3 py-2 flex items-center gap-2 text-white transition-all ${
                loading
                  ? 'bg-[#559383] cursor-progress'
                  : 'bg-[#008160] hover:bg-emerald-600 cursor-pointer'
              }`}>
              <span className={'text-sm'}>{loading ? 'Saving changes...' : 'Save Changes'}</span>
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default function SelectedWebsiteSettingsDetails({
  data
}: {
  data: { siteId: number | string; name: string; main_url: string };
}) {
  const { loadWebsiteSettings, loading: settingsLoading } = useAppSettings();
  const [loading, setLoading] = useState<boolean>(true);
  const [settingsData, setSettingsData] = useState<ISettingsTab[]>([]);

  const fetchWebsiteSettings = async (siteId: number) => {
    try {
      const response = await loadWebsiteSettings({ siteId });
      if (response) {
        setSettingsData(response);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (data.siteId) {
      fetchWebsiteSettings(Number(data.siteId));
    }
  }, [data.siteId]);

  return (
    <div className={'h-[72vh] lg:col-span-2 bg-white border-2 border-gray-200 rounded-lg p-5'}>
      {loading ? (
        <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
          <LoadingSpinner color={'text-[#008160]'} />
        </div>
      ) : (
        <>
          <div className={''}>
            <h4 className={'font-semibold text-lg'}>{data.name}</h4>
            <div className={'flex gap-1 my-1 text-emerald-600'}>
              <span>
                <i className="fi fi-rr-link text-xs"></i>
              </span>
              <span className={'text-sm md:text-sm truncate'}>{data.main_url}</span>
            </div>
          </div>
          {loading || settingsLoading ? (
            <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
            </div>
          ) : (
            settingsData && (
              <WebsiteSettingsOptions
                key={data.siteId}
                siteId={data.siteId as number}
                tabs={settingsData}
              />
            )
          )}
        </>
      )}
    </div>
  );
}
