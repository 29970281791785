import SubscriptionInvoicesListItemCard from './SubscriptionInvoicesListItemCard';

export default function SubscriptionHistory({
  userSubscription,
  history,
  error
}: {
  userSubscription: any;
  history: any;
  error: string | null;
}) {
  return (
    <div className={'mt-2 grid gap-2'}>
      {error ? (
        <div className={'flex justify-center items-center p-5 text-center'}>
          <h5>{error}</h5>
        </div>
      ) : history && history.length > 0 ? (
        history.map((item: any, index: number) => (
          <SubscriptionInvoicesListItemCard
            key={index}
            data={item}
            plan={userSubscription && userSubscription.subscription.plan}
            plan_name={userSubscription && userSubscription.subscription.plan_name}
          />
        ))
      ) : (
        <div>
          <span>No payment history</span>
        </div>
      )}
    </div>
  );
}
