import moment from 'moment';
import { useAuthUser } from 'react-auth-kit';
import { ICancelledSubscriptionItem } from '../../../interfaces/analytic';

export default function CancelledSubscriptionListCard({
  data
}: {
  data: ICancelledSubscriptionItem;
}) {
  const auth = useAuthUser();

  return (
    <>
      <div
        className={
          'border border-gray-200 bg-white hover:border-gray-400 cursor-default w-full rounded-md p-4'
        }>
        <div className={'flex gap-3 md:gap-5'}>
          <div className={'w-full flex flex-col justify-between'}>
            <div>
              <div className={'flex justify-between'}>
                <div className={'flex flex-col lg:flex-row gap-2 lg:items-center'}>
                  <div className={'flex gap-3'}>
                    {/*<span className={'font-medium'}>{data.}</span>*/}
                    <h5 className={'text-base text-[#008160]'}>
                      {data.plan == 0
                        ? 'Starter'
                        : data.plan == 1
                        ? 'Grow'
                        : data.plan == 2
                        ? 'Scale'
                        : data.plan == 3
                        ? 'Scale Plus'
                        : ''}{' '}
                      Plan
                    </h5>
                  </div>
                  {auth()?.super_user == 1 && (
                    <>
                      <span className={'hidden lg:flex w-1 h-1 rounded-full bg-gray-400'}></span>

                      <div className={'flex gap-2'}>
                        <span>
                          <i className="fi fi-rr-user text-sm items-center"></i>
                        </span>
                        <span className={'text-sm text-gray-700'}>
                          {data?.full_name} ({data?.login})
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/*<div className={'flex flex-col md:flex-row justify-between md:items-end'}>*/}
              <div className={'grid grid-cols-5 gap-2 md:gap-5 lg:gap-8 mt-3 lg:mt-1'}>
                <div>
                  <h6 className={'text-xs'}>PERIOD</h6>
                  <p className={'font-medium text-sm capitalize'}>{data.plan_interval}</p>
                </div>
                <div>
                  <h6 className={'text-xs'}>START DATE</h6>
                  <p className={'font-medium text-sm'}>
                    {moment(data.plan_period_start).format('ll')}
                  </p>
                </div>
                <div>
                  <h6 className={'text-xs'}>END DATE</h6>
                  <p className={'font-medium text-sm'}>
                    {moment(data.plan_period_end).format('ll')}
                  </p>
                </div>
                <div>
                  <h6 className={'text-xs'}>CANCELLED DATE</h6>
                  <p className={'font-medium text-sm'}>
                    {moment(data.is_cancelled_date).format('ll')}
                  </p>
                </div>
                <div>
                  <h6 className={'text-xs'}>NEXT AMOUNT</h6>
                  <p className={'font-medium text-sm'}>${data.paid_amount}</p>
                </div>
              </div>
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
