import { ChartPeriod, ChartType, IAnalytic, IChart } from '../../../interfaces/analytic';
import MonthlySubscription from './Charts/MonthlySubscription';
import StatusChart from './Charts/StatusChart';
import PackageChart from './Charts/PackageChart';
import { useState } from 'react';
import { periods } from '../../../constants';

export default function SubscriptionSummary({ data }: { data: IAnalytic }) {
  const [tab, setTab] = useState<number>(2);

  return (
    <div className={'flex flex-col gap-5'}>
      <div
        className={
          'lg:col-span-1 rounded-md border border-gray-200 p-3 md:p-5 bg-white text-gray-500'
        }>
        <div className={'flex gap-2 justify-between items-start'}>
          <span className={'text-sm'}>Active Subscription Package Plans</span>
          <i className="fi fi-sr-box-open text-xl lg:text-xl text-gray-400"></i>
        </div>
        <div className={'grid grid-cols-3 gap-2'}>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-2xl lg:text-3xl font-medium'}>
                {data &&
                  data.subscriptions?.plans.basic +
                    data.subscriptions?.plans.starter +
                    data.subscriptions?.plans.grow +
                    data.subscriptions?.plans.scale +
                    data.subscriptions?.plans['scale plus']}
              </span>
              <span className={'text-xs lg:text-sm'}>Total Active Plans</span>
            </div>
          </div>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-2xl lg:text-3xl font-medium'}>
                {data && data.subscriptions?.plans.basic}
              </span>
              <span className={'text-xs lg:text-sm'}>Basic Plans</span>
            </div>
          </div>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-2xl lg:text-3xl font-medium'}>
                {data && data.subscriptions?.plans.starter}
              </span>
              <span className={'text-xs lg:text-sm'}>Starter Plans</span>
            </div>
          </div>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-2xl lg:text-3xl font-medium'}>
                {data && data.subscriptions?.plans.grow}
              </span>
              <span className={'text-xs lg:text-sm'}>Grow Plans</span>
            </div>
          </div>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-2xl lg:text-3xl font-medium'}>
                {data && data.subscriptions?.plans.scale}
              </span>
              <span className={'text-xs lg:text-sm'}>Scale Plans</span>
            </div>
          </div>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-2xl lg:text-3xl font-medium'}>
                {data && data.subscriptions?.plans['scale plus']}
              </span>
              <span className={'text-xs lg:text-sm'}>Scale Plus Plans</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          'lg:col-span-1 rounded-md border border-gray-200 p-3 md:p-5 bg-white text-gray-500'
        }>
        <div className={'flex gap-2 justify-between items-start'}>
          <span className={'text-sm'}>Subscriptions Status</span>
          <i className="fi fi-sr-users text-xl lg:text-xl text-gray-400"></i>
        </div>
        <div className={'mt-2'}>
          <div className={'grid grid-cols-2 lg:grid-cols-4 gap-3'}>
            <div
              className={
                'flex flex-col justify-center items-center border-l-2 border-blue-600 shadow text-black p-2 rounded'
              }>
              <span className={'text-xs rounded-sm px-2 py-1 w-full text-center'}>Active</span>
              <div className={'my-1'}>
                <span className={'text-2xl lg:text-3xl font-medium'}>
                  {data && data.subscriptions?.statuses.active_not_cancelled}
                </span>
              </div>
              <div className={'w-full grid grid-cols-2 gap-2'}>
                {data && (
                  <PlanCard
                    title={'Basic'}
                    value={data.subscriptions.basic.active_not_cancelled}
                    type={CardEnum.basic}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Starter'}
                    value={data.subscriptions.starter.active_not_cancelled}
                    type={CardEnum.starter}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Grow'}
                    value={data.subscriptions.grow.active_not_cancelled}
                    type={CardEnum.grow}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale'}
                    value={data.subscriptions.scale.active_not_cancelled}
                    type={CardEnum.scale}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale Plus'}
                    value={data.subscriptions['scale plus'].active_not_cancelled}
                    type={CardEnum.scalePlus}
                  />
                )}
              </div>
            </div>
            <div
              className={
                'flex flex-col justify-center items-center border-l-2 border-emerald-600 shadow text-black p-2 rounded'
              }>
              <span className={'text-xs rounded-sm px-2 py-1 w-full text-center'}>
                Active + Pending Subscriptions
              </span>
              <div className={'my-1'}>
                <span className={'text-2xl lg:text-3xl font-medium'}>
                  {data && data.subscriptions?.statuses.active}
                </span>
              </div>
              <div className={'w-full grid grid-cols-2 gap-2'}>
                {data && (
                  <PlanCard
                    title={'Basic'}
                    value={data.subscriptions.basic.active}
                    type={CardEnum.basic}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Starter'}
                    value={data.subscriptions.starter.active}
                    type={CardEnum.starter}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Grow'}
                    value={data.subscriptions.grow.active}
                    type={CardEnum.grow}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale'}
                    value={data.subscriptions.scale.active}
                    type={CardEnum.scale}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale Plus'}
                    value={data.subscriptions['scale plus'].active}
                    type={CardEnum.scalePlus}
                  />
                )}
              </div>
            </div>
            <div
              className={
                'flex flex-col justify-center items-center border-l-2 border-emerald-600 shadow text-black p-2 rounded'
              }>
              <span className={'text-xs rounded-sm px-2 py-1 w-full text-center'}>
                Active Trials
              </span>
              <div className={'my-1'}>
                <span className={'text-2xl lg:text-3xl font-medium'}>
                  {data && data.subscriptions?.statuses.active_trial}
                </span>
              </div>
              <div className={'w-full grid grid-cols-2 gap-2'}>
                {data && (
                  <PlanCard
                    title={'Basic'}
                    value={data.subscriptions.basic.active_trial}
                    type={CardEnum.basic}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Starter'}
                    value={data.subscriptions.starter.active_trial}
                    type={CardEnum.starter}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Grow'}
                    value={data.subscriptions.grow.active_trial}
                    type={CardEnum.grow}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale'}
                    value={data.subscriptions.scale.active_trial}
                    type={CardEnum.scale}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale Plus'}
                    value={data.subscriptions['scale plus'].active_trial}
                    type={CardEnum.scalePlus}
                  />
                )}
              </div>
            </div>
            <div
              className={
                'flex flex-col justify-center items-center border-l-2 border-red-600 shadow text-black p-2 rounded'
              }>
              <span className={'text-xs rounded-sm px-2 py-1 w-full text-center'}>Cancelled</span>
              <div className={'my-1'}>
                <span className={'text-2xl lg:text-3xl font-medium'}>
                  {data && data.subscriptions?.statuses.cancelled}
                </span>
              </div>
              <div className={'w-full grid grid-cols-2 gap-2'}>
                {data && (
                  <PlanCard
                    title={'Basic'}
                    value={data.subscriptions.basic.cancelled}
                    type={CardEnum.basic}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Starter'}
                    value={data.subscriptions.starter.cancelled}
                    type={CardEnum.starter}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Grow'}
                    value={data.subscriptions.grow.cancelled}
                    type={CardEnum.grow}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale'}
                    value={data.subscriptions.scale.cancelled}
                    type={CardEnum.scale}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale Plus'}
                    value={data.subscriptions['scale plus'].cancelled}
                    type={CardEnum.scalePlus}
                  />
                )}
              </div>
            </div>
            <div
              className={
                'flex flex-col justify-center items-center border-l-2 border-gray-600 shadow text-black p-2 rounded'
              }>
              <span className={'text-xs rounded-sm px-2 py-1 w-full text-center'}>Paused</span>
              <div className={'my-1'}>
                <span className={'text-2xl lg:text-3xl font-medium'}>
                  {data && data.subscriptions?.statuses.paused}
                </span>
              </div>
              <div className={'w-full grid grid-cols-2 gap-2'}>
                {data && (
                  <PlanCard
                    title={'Basic'}
                    value={data.subscriptions.basic.paused}
                    type={CardEnum.basic}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Starter'}
                    value={data.subscriptions.starter.paused}
                    type={CardEnum.starter}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Grow'}
                    value={data.subscriptions.grow.paused}
                    type={CardEnum.grow}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale'}
                    value={data.subscriptions.scale.paused}
                    type={CardEnum.scale}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale Plus'}
                    value={data.subscriptions['scale plus'].paused}
                    type={CardEnum.scalePlus}
                  />
                )}
              </div>
            </div>
            <div
              className={
                'flex flex-col justify-center items-center border-l-2 border-cyan-600 shadow text-black p-2 rounded'
              }>
              <span className={'text-xs rounded-sm px-2 py-1 w-full text-center'}>
                Pending Cancellation
              </span>
              <div className={'my-1'}>
                <span className={'text-2xl lg:text-3xl font-medium'}>
                  {data && data.subscriptions?.statuses.pending_cancellation}
                </span>
              </div>
              <div className={'w-full grid grid-cols-2 gap-2'}>
                {data && (
                  <PlanCard
                    title={'Basic'}
                    value={data.subscriptions.basic.pending_cancellation}
                    type={CardEnum.basic}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Starter'}
                    value={data.subscriptions.starter.pending_cancellation}
                    type={CardEnum.starter}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Grow'}
                    value={data.subscriptions.grow.pending_cancellation}
                    type={CardEnum.grow}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale'}
                    value={data.subscriptions.scale.pending_cancellation}
                    type={CardEnum.scale}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale Plus'}
                    value={data.subscriptions['scale plus'].pending_cancellation}
                    type={CardEnum.scalePlus}
                  />
                )}
              </div>
            </div>
            <div
              className={
                'flex flex-col justify-center items-center border-l-2 border-amber-600 shadow text-black p-2 rounded'
              }>
              <span className={'text-xs rounded-sm px-2 py-1 w-full text-center'}>Incomplete</span>
              <div className={'my-1'}>
                <span className={'text-2xl lg:text-3xl font-medium'}>
                  {data && data.subscriptions?.statuses.incomplete}
                </span>
              </div>
              <div className={'w-full grid grid-cols-2 gap-2'}>
                {data && (
                  <PlanCard
                    title={'Basic'}
                    value={data.subscriptions.basic.incomplete}
                    type={CardEnum.basic}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Starter'}
                    value={data.subscriptions.starter.incomplete}
                    type={CardEnum.starter}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Grow'}
                    value={data.subscriptions.grow.incomplete}
                    type={CardEnum.grow}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale'}
                    value={data.subscriptions.scale.incomplete}
                    type={CardEnum.scale}
                  />
                )}
                {data && (
                  <PlanCard
                    title={'Scale Plus'}
                    value={data.subscriptions['scale plus'].incomplete}
                    type={CardEnum.scalePlus}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={'grid lg:grid-cols-2'}>
          <div className={'p-10'}>{data && <PackageChart data={data.subscriptions?.plans} />}</div>
          <div className={'p-10'}>
            {data && <StatusChart data={data.subscriptions?.statuses} />}
          </div>
        </div>
      </div>
      <div className={'lg:col-span-2 rounded-md border border-gray-200 p-3 md:p-5'}>
        <div className={'flex gap-4 mb-5'}>
          {periods &&
            periods.map((item, index) => (
              <button
                type={'button'}
                onClick={() => setTab(item.id)}
                key={index}
                className={`${
                  tab === item.id
                    ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                    : 'border-transparent'
                } py-2 border-b-2 hover:border-gray-300 font-medium text-sm flex gap-3`}>
                <span className={'text-sm'}>{item.name}</span>
              </button>
            ))}
        </div>
        {data && (
          <div className={'p-5'}>
            {tab == 2 ? (
              <div className={'grid grid-cols-1 lg:grid-cols-2 gap-5'}>
                <div>
                  <MonthlySubscription
                    period={ChartPeriod.week}
                    type={ChartType.subscription}
                    data={data.chart as IChart}
                  />
                </div>
                <div>
                  <MonthlySubscription
                    period={ChartPeriod.week}
                    type={ChartType.cancelled_subscriptions}
                    data={data.chart as IChart}
                  />
                </div>
              </div>
            ) : (
              <div className={'grid grid-cols-1 lg:grid-cols-2 gap-5'}>
                <div>
                  <MonthlySubscription
                    period={ChartPeriod.annual}
                    type={ChartType.subscription}
                    data={data.chart as IChart}
                  />
                </div>
                <div>
                  <MonthlySubscription
                    period={ChartPeriod.annual}
                    type={ChartType.cancelled_subscriptions}
                    data={data.chart as IChart}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={
          'lg:col-span-2 rounded-md border border-gray-200 p-3 md:p-5 bg-white text-gray-500'
        }>
        <div className={'flex gap-2 justify-between items-start'}>
          <span className={'text-sm'}>Subscription Intervals (Periods)</span>
          <i className="fi fi-ss-calendar-clock text-xl lg:text-xl text-gray-400"></i>
        </div>
        <div className={'mt-2 grid gap-5'}>
          <div>
            <span>Monthly</span>

            <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3'}>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-blue-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Active</span>
                <span className={'text-xs'}>Active in-use subscriptions</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.month.active_not_cancelled
                      ? data.subscriptions.interval.month.active_not_cancelled
                      : 0}
                  </span>
                </div>
              </div>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-emerald-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Active + Pending Subscriptions</span>
                <span className={'text-xs'}>
                  All active subscriptions, including pending cancellations
                </span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.month.active
                      ? data.subscriptions.interval.month.active
                      : 0}
                  </span>
                </div>
              </div>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-emerald-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Active Trials</span>
                <span className={'text-xs'}>All active subscriptions that are on trial</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.month.active_trial
                      ? data.subscriptions.interval.month.active_trial
                      : 0}
                  </span>
                </div>
              </div>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-red-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Cancelled</span>
                <span className={'text-xs'}>All successfully cancelled subscriptions</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.month.cancelled
                      ? data.subscriptions.interval.month.cancelled
                      : 0}
                  </span>
                </div>
              </div>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-gray-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Paused</span>
                <span className={'text-xs'}>All paused subscriptions</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.month.paused
                      ? data.subscriptions.interval.month.paused
                      : 0}
                  </span>
                </div>
              </div>

              <div
                className={
                  'flex flex-col justify-between border-l-2 border-cyan-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Pending Cancellation</span>
                <span className={'text-xs'}>All cancelled subscriptions, but period not due</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.month.pending_cancellation
                      ? data.subscriptions.interval.month.pending_cancellation
                      : 0}
                  </span>
                </div>
              </div>

              <div
                className={
                  'flex flex-col justify-between border-l-2 border-yellow-500 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Incomplete</span>
                <span className={'text-xs'}>All incomplete subscriptions</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.month.incomplete
                      ? data.subscriptions.interval.month.incomplete
                      : 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <span>Yearly</span>

            <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3'}>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-blue-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Active</span>
                <span className={'text-xs'}>Active in-use subscriptions</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.year.active_not_cancelled
                      ? data.subscriptions.interval.year.active_not_cancelled
                      : 0}
                  </span>
                </div>
              </div>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-emerald-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Active + Pending Subscriptions</span>
                <span className={'text-xs'}>
                  All active subscriptions, including pending cancellations
                </span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.year.active
                      ? data.subscriptions.interval.year.active
                      : 0}
                  </span>
                </div>
              </div>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-emerald-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Active Trials</span>
                <span className={'text-xs'}>All active subscriptions that are on trial</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.year.active_trial
                      ? data.subscriptions.interval.year.active_trial
                      : 0}
                  </span>
                </div>
              </div>

              <div
                className={
                  'flex flex-col justify-between border-l-2 border-red-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Cancelled</span>
                <span className={'text-xs'}>All successfully cancelled subscriptions</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.year.cancelled
                      ? data.subscriptions.interval.year.cancelled
                      : 0}
                  </span>
                </div>
              </div>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-gray-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Paused</span>
                <span className={'text-xs'}>All paused subscriptions</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.year.paused
                      ? data.subscriptions.interval.year.paused
                      : 0}
                  </span>
                </div>
              </div>

              <div
                className={
                  'flex flex-col justify-between border-l-2 border-cyan-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Pending Cancellation</span>
                <span className={'text-xs'}>All cancelled subscriptions, but period not due</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.year.pending_cancellation
                      ? data.subscriptions.interval.year.pending_cancellation
                      : 0}
                  </span>
                </div>
              </div>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-yellow-500 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}>Incomplete</span>
                <span className={'text-xs'}>All incomplete subscriptions</span>
                <div className={'mt-1'}>
                  <span className={'text-2xl lg:text-3xl font-medium'}>
                    {data && data.subscriptions.interval.year.incomplete
                      ? data.subscriptions.interval.year.incomplete
                      : 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

enum CardEnum {
  basic,
  grow,
  starter,
  scale,
  scalePlus
}

function PlanCard({ title, value, type }: { title: string; value: number; type: CardEnum }) {
  return (
    <div
      className={`flex flex-col justify-between rounded-md bg-gradient-to-tr ${
        type == CardEnum.grow
          ? 'from-emerald-100 to-blue-100'
          : type == CardEnum.starter
          ? 'from-red-100 to-green-100'
          : type == CardEnum.scale
          ? 'from-amber-100 to-blue-100'
          : type == CardEnum.scalePlus
          ? 'from-indigo-100 to-red-100'
          : 'from-pink-100 to-amber-100'
      } p-2`}>
      <div className={'flex flex-col'}>
        <span className={'text-2xl lg:text-base font-medium'}>{value ? value : 0}</span>
        <span className={'text-xs'}>{title}</span>
      </div>
    </div>
  );
}
