import { useCallback, useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import Layout from '../../../layouts/global/Layout';
import { useClients } from './useClients';
import { IClient } from '../../../interfaces/client';
import SearchClients from '../../../components/form/SearchClients';
import ClientListCard from '../../../feature/Clients/ClientListCard';
import PrimaryButtonWithIcon from '../../../components/global/ui/buttons/PrimaryButtonWithIcon';
import AddNewClient from '../../../feature/Clients/AddNewClient';
import PageTitle from '../../../components/global/PageTitle';
import { useQuery } from 'react-query';
import _ from 'lodash';

export default function Clients() {
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');

  const [addClientOpen, setAddClientOpen] = useState<boolean>(false);

  const { listClients, loading: clientsLoading } = useClients();

  // Debounce searchKey updates
  const debounceSearch = useCallback(
    _.debounce((search) => setSearchKey(search), 1000),
    []
  );

  const { data, isLoading } = useQuery({
    queryKey: ['clients', page, searchKey],
    queryFn: () => listClients({ page, search: searchKey }),
    keepPreviousData: true
  });

  const handleSearchChange = (search: any) => {
    debounceSearch(search);
  };

  const pages = Array.from(
    { length: data && data.additional && data.additional.pagination.total_pages },
    (_, index) => index + 1
  );

  return (
    <Layout>
      {addClientOpen && <AddNewClient closeModal={() => setAddClientOpen(false)} />}
      <PageTitle title={'Clients & Users'} />
      <div>
        {isLoading ? (
          <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
            <LoadingSpinner color={'text-[#008160]'} />
          </div>
        ) : (
          <div>
            {data && data.msg && (
              <>
                <div className={'flex flex-col lg:flex-row justify-between gap-5'}>
                  <div
                    className={
                      'flex flex-col lg:flex-row gap-3 items-start lg:items-center lg:w-[20vw]'
                    }>
                    <SearchClients onSearchChange={handleSearchChange} />
                    <div className={'flex gap-2 items-center'}>
                      <span>Total: </span>
                      <span className={'font-medium'}>
                        {data.additional?.pagination?.total_records || 0}
                      </span>
                    </div>
                  </div>
                  {data && data.msg.length > 0 && (
                    <div className={'hidden lg:flex'}>
                      <PrimaryButtonWithIcon
                        handler={() => setAddClientOpen(true)}
                        title={'Add a user'}
                        icon={<i className="fi fi-rr-plus text-xs"></i>}
                      />
                    </div>
                  )}
                </div>
                <div className={'my-3 grid gap-2 pr-2'}>
                  {isLoading ? (
                    <div
                      className={
                        'p-5 h-[70vh] flex flex-col justify-center items-center text-center'
                      }>
                      <LoadingSpinner color={'text-[#008160]'} />
                      {/*<p>Loading client accounts</p>*/}
                    </div>
                  ) : data && data.msg.length > 0 ? (
                    <div>
                      <div
                        className={
                          'hidden lg:grid grid-cols-1 lg:grid-cols-12 gap-2 lg:gap-5 px-3 py-2 text-sm font-medium bg-gray-100 mb-1'
                        }>
                        <div className={'col-span-4 flex flex-col'}>
                          <span>Full name</span>
                        </div>
                        <div className={'col-span-3'}>
                          <span>Username</span>
                        </div>
                        <div className={'col-span-1'}>
                          <span>User ID</span>
                        </div>
                        <div className={'col-span-1'}>
                          <span>Status</span>
                        </div>
                        <div className={'col-span-3 flex gap-2'}>
                          <span>Created</span>
                        </div>
                      </div>
                      <div className={'flex flex-col gap-2 h-[63vh] overflow-auto slim-scroll'}>
                        {data &&
                          data.msg.map((item: IClient, index: number) => (
                            <ClientListCard data={item} key={index} />
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={'h-[60vh] flex flex-col justify-center items-center text-center'}>
                      <h4 className={'font-medium mt-5'}>No client found.</h4>
                    </div>
                  )}
                </div>
                <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start w-full gap-2 mt-4">
                  <span>
                    Showing page {page} of {data.additional?.pagination?.total_pages}
                  </span>
                  <div className={'flex gap-5'}>
                    <div className={'flex flex-wrap gap-2'}>
                      {pages.map((pageNumber) => (
                        <button
                          key={pageNumber}
                          className={`px-3 lg:px-3 py-1 lg:py-1 border ${
                            page === pageNumber
                              ? 'bg-emerald-100 hover:bg-emerald-100 text-emerald-600'
                              : 'bg-emerald-500 hover:bg-emerald-700 text-white'
                          } rounded text-sm`}
                          onClick={() => setPage(pageNumber)}
                          disabled={page === pageNumber}>
                          {pageNumber}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}
