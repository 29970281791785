import { IInput } from '../../interfaces/components';

export default function InputWithoutLabel({
  value,
  name,
  changeHandler,
  type = 'text',
  placeholder,
  autoFocus = false
}: IInput) {
  return (
    <div className="w-full rounded border border-gray-300 mt-2">
      <input
        value={value}
        onChange={changeHandler}
        name={name}
        className={'bg-transparent w-full outline-0 p-3'}
        type={type}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
    </div>
  );
}
